import { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../Hooks/functions/Cookies-and-Hooks';
import { useAxiosCall } from '../API/AxiosCall';

import { useAddUserName, useLogin, useLoginLogic } from './login-logic';
import { useLoginToken, useUserType } from '../Hooks/loginHooks';
import { useUserNameRes, useVerifyRes } from '../Hooks/msgHooks';
import { useGetSession } from './session-logic';
import { useConnectionContext } from './connection-logic';

    // Step 1: Create a Context
    const RoutingContext = createContext();

// Step 2: Provide the Context
export function RoutingContextProvider({ children }) {

    const navigate = useNavigate();
    const AxiosCall = useAxiosCall();
    const falsyValues = [null, undefined, ""];



    const userType = useUserType();
    const loginToken = useLoginToken();
    const Online = useConnectionContext();

    const { Login,  Verify,   ResendOTP,  AddUserName } = useLoginLogic();
    
    const GetSession = useGetSession();

    // # Local Function
    function CheckAllCookie(){
        if (
            // falsyValues.includes(getCookie("loginToken")) ||
            falsyValues.includes(getCookie("userType")) ||
            // falsyValues.includes(getCookie("loginKey")) ||
            falsyValues.includes(getCookie("Phone")) ||
            falsyValues.includes(getCookie("SalonId")) 
        ){
        navigate("/");
            return false;
        } else {
            return true;
        }
    }

    // # Local Function
    const isPreLoggedin = ()=>{
      const loginKey = getCookie("loginKey");
      if (loginKey!==null&&loginKey!==undefined&&loginKey!=="") {
        return true;
      } else {
        return false;
      }
    }

    // # Local Function 
    const isLoggedin =  async () =>{
      const loginToken = getCookie("loginToken");
      if (loginToken!==null&&loginToken!==undefined&&loginToken!=="") {
          const login = await AxiosCall("/check-session",{loginToken: getCookie("loginToken")});
          if(login==="offline"){
              return "offline";
          }else{
              if (login.login_expired) {
                console.log("isLogggedin : false, expired");
                return false;
              }else{
                console.log("isLogggedin : true");
                return true;
              }
          }
      } else {
        console.log("isLogggedin : false last");
        return false;
      }
    }



    // # Global Main Function For All Components 
    const checkOnlineAndLogin = async ()=>{
      const loginState = await isLoggedin();
      if(loginState.valueOf()!=="offline"){ // No Action
        if (loginState.valueOf()) { // Yes Action if-else 
          return true;
        }else{
          navigate("/login");
          return false;
        }
      }
    }

// # ---------- Effective Veriables Imports are Here ---------- 
    const verifyRes = useVerifyRes(); 

/**
 * ---------- All Effective Navigations of the App ----------
 */

useEffect(()=>{

  if (verifyRes.OTP_Verification_Status===true) {
    if(verifyRes.isusernameExists===false){
      navigate("/username")
    }else if(verifyRes.isusernameExists===true) {
      // navigate("/username")
      navigate("/Impact");
    }else if (verifyRes.userType ==="SalonAdmin"){
      navigate("/admin");
    }
  }

},[verifyRes])


  const userNameRes = useUserNameRes();
  useEffect(()=>{
    if (userNameRes.updated!==null && userNameRes.updated) {
      navigate("/Impact");
    }
  },[userNameRes])


    const GetStarted = async ()=>{
      const loginState = await checkOnlineAndLogin();
      console.log("Login State : ",loginState);
      if(loginState){
        if(getCookie("userType")==="SalonAdmin"){
          navigate("/admin");
        }else{
          navigate("/home/catalog/hair_care");
        }
    }
  }

    const GetOTP = async (Phone)=>{
      console.log("loginToken",loginToken)
      if(loginToken!==null && userType=== "SalonAdmin" && loginToken.length >200){
        navigate("/admin");
      }
      else if(loginToken!==null && userType=== "Customer" && loginToken.length >200){
        navigate("/home/catalog/hair_care");
      }else{
        Login(Phone);
        if(Online()){
          navigate("/verify");
        }
      }
    }


    const OTP_Submit = async (OTP)=>{
      Verify(OTP);
    }

    const SaveUserName = async (username)=>{
        AddUserName(username);
      // const loginState = await isLoggedin();
    }


  const ImpactRouter = ()=>{
    navigate("/home/catalog/hair_care");
  }

    const StartSession = async ()=>{
      // const loginState = await isLoggedin();
    }



    const ActiveSessions = async ()=>{
      // const loginState = await isLoggedin();
      // __SessionContext.getSession();
      navigate("/active-sessions");
      GetSession();
    }



    const AddProducts = async ()=>{
      const loginState = await isLoggedin();
    }

    const EndSession = ()=>{
      
    }


  return (

    <RoutingContext.Provider value={{ 

        // move,
        CheckAllCookie,
        isPreLoggedin,
        isLoggedin,

        GetStarted,
        GetOTP,
        OTP_Submit,
        SaveUserName,
        ImpactRouter,

        StartSession,

        ActiveSessions,
        AddProducts,
        EndSession

      }}>

      {children}
    </RoutingContext.Provider>
  );

}

// Step 3: Create a Custom Hook

export function useRoutingContext() {
    return useContext(RoutingContext);
}

export function useTest() {
    return useContext(RoutingContext).test;
}


export function useIsLoggedin() {
  return useContext(RoutingContext).isLoggedin();
}


export function useIsPreLoggedin() {
  return useContext(RoutingContext).isPreLoggedin();
}


export function useGetStarted() {
  return useContext(RoutingContext).GetStarted;
}

export function useGetOTP() {
  return useContext(RoutingContext).GetOTP;
}

export function useOTP_Submit() {
  return useContext(RoutingContext).OTP_Submit;
}

export function useSaveUserName() {
  return useContext(RoutingContext).SaveUserName;
}

export function useImpactRouter(){
  return useContext(RoutingContext).ImpactRouter;
}

export function useStartSession() {
  return useContext(RoutingContext).StartSession;
}
export function useActiveSessions() {
  return useContext(RoutingContext).ActiveSessions;
}

export function useAddProducts() {
  return useContext(RoutingContext).AddProducts;
}

export function useEndSession() {
  return useContext(RoutingContext).EndSession;
}

export function useCheckAllCookie() {
  return useContext(RoutingContext).CheckAllCookie;
}
