import React, { useContext, useEffect, useState } from "react";
import "../../Style/OwnerStyle/ActiveSession.css";
import Order from "./Tiny-Components/order_summary";
import { useNavigate } from "react-router-dom";
import Config from "../../utils/config.json";
import Prompt from "../customer/prompt";
import { useCartId, useSessionObj, useSetEndResUrl } from "../../Contexts/Hooks/sessionHooks";
import { useGetSession, useOpenPrompt, useRemoveSession, useTogglePrompt } from "../../Contexts/APP/session-logic";

const k = new Date();
const zero = (e) => {
  if (e <= 9) {
    return `0${e}`;
  } else {
    return e;
  }
};


const ActiveSession = () => {
  
  const setEndresUrl = useSetEndResUrl();
  const GetSession = useGetSession();
  const sessionObj = useSessionObj();
  const TogglePrompt = useTogglePrompt();
  const cartId = useCartId();
  const RemoveSession = useRemoveSession();
  const openPrompt = useOpenPrompt();

  const navigate = useNavigate();

  const [sessionList, setSessionList] = useState(false);
  const [allSessions, setAllSessions] = useState(false);

  let round = 1 ;
  
  useEffect(()=>{
    setEndresUrl({
      url:"/active-sessions",
      status:false
    })
    GetSession();
  },[]);


const falsyValues = [null,undefined,"",false,{}]
  useEffect(()=>{
    if (allSessions) {
      setSessionList(sessionObj.SessionsList);      
    } else {
      if (!falsyValues.includes(sessionObj.SessionsList)) {
        let list = [];
        for (const i of sessionObj.SessionsList) {
          if(i.Status==="Active"){
            list.push(i);
          }
        }
        if (list.length===0) {
          setSessionList(false);
        } else {
          setSessionList(list);
        }
      }
    }
  },[sessionObj,allSessions])

    return (
      <>
          {/* {Config.Application_Mode==="Test"?
    <div style={{ scale:"0.8", zIndex:"+3", padding : "8px 18px", backgroundColor:"rgb(14 187 14)", color:"#fff" , borderRadius:"10px", position:"absolute", bottom:"20px", left:"20px"}}>
      <h4> Server Data : {getCookie("SessionData")}  </h4>
    </div>:null} */}
    <img onClick={()=>{navigate("/admin")}} style={{position:"absolute",top:"22px",left:"15px",width:"30px"}} src="/Images/backarrow.png" alt="Back Button" />
      <div className="active-people">
        <div style={{position:"fixed", zIndex:5, top:"70px",left:"8px",display:"flex",alignItems:"center",scale:"0.75"}}>
          <label htmlFor="All-With-inActive"style={{width:"max-content",marginTop:"5px"}} > <span style={{color:"#fff",fontSize:"2rem",lineHeight:"30px"}} >All &nbsp; </span> </label> 
            <label className="SWITCH">
                <input id="All-With-inActive" onChange={()=>{setAllSessions(!allSessions)}} type="checkbox"/>
                <span className="SLIDER round"></span>
            </label>
        </div>

        <h2>Active Sessions</h2> 
        <h3 style={{color:"white",textAlign: "center"}}>{zero(k.getDate())+"."+zero(k.getMonth()+1)+"."+k.getFullYear()}</h3>
        
        <img id="refresh" className="rotate-0" onClick={(e)=>{
          GetSession();
          const rotatingElement=document.getElementById("refresh");
          rotatingElement.classList.toggle('rotate-animation');
          round++;          
          console.log(e)}} style={{position:"absolute",top:"15px",right:"20px",scale:"0.68",transition:"0.7s",borderRadius:"50%"}} src="./Images/Icons/refresh1.png" alt="" />
        <div className="scrollbar" style={{overflowY:"scroll",height:"80vh"}}>
            {/* {allSessions&&<span style={{color:"#fff"}}> All Sessions Selected...</span>} */}
        {sessionList !== undefined && sessionList !== false  &&
        
        sessionList.toReversed().map((value, index, array)=>{
          return <Order key={value.Phone} data ={value} />
        })
      }{sessionList===false && <img style={{width:"80vw", display:"block", margin:"135px auto 0px auto", height:"80vw", borderRadius:"50%"}} src="./Images/empty-active-session.avif" alt="Session Image" />
      
    }
    <div style={{width:"inherit",height:"100px"}}></div>
    </div>
      </div>
      {
     
     openPrompt &&  <Prompt action={TogglePrompt} 
     remove={RemoveSession} 
     customer={cartId}
     clear={TogglePrompt}
     />
     }
    </>
  );
};


export default ActiveSession;
