import Config from "../../utils/config.json";
import "../../Style/CustomerStyle/frontpage.css";
import {useParams,useNavigate} from "react-router-dom";
import StoreMap from "../../utils/keyMap.json";
import {getCookie} from "../../Contexts/Hooks/functions/Cookies-and-Hooks";
import zero4 from "../../Functions/text-modification-functions";
import { useGetStarted } from "../../Contexts/APP/routing-logic";
import { useSetSalonId } from "../../Contexts/Hooks/loginHooks";
const Frontpage = () => {
  console.log("Front Page is called...");
  const navigate = useNavigate();
  
  const GetStarted = useGetStarted();
  const SetSalonId = useSetSalonId();

  var { Id } = useParams();
  let correctURL = false;
  for (const i of StoreMap) {
    if(i.key === Id){
      correctURL = true;
      let SalonId = zero4(i.id);
      setTimeout(()=>{SetSalonId(SalonId);},200)      
      document.title=("Store-0"+i.id);
      break;
    }
  }
  if(!correctURL){
   setTimeout(() => {
     navigate("/");
  }, 250);
  }
  else{ 
  return (
    <>
        {Config.Application_Mode==="Test"?
    <div style={{ scale:"0.8", padding : "8px 18px", backgroundColor:"rgb(14 187 14)", color:"#fff" , borderRadius:"10px", position:"absolute", top:"5px", left:"5px"}}>
      <h4> SalonId : {getCookie("SalonId")} </h4>

    </div>:null}
      <div className="Front-Page-container">
      <img style={{width:"1px",height:"1px",position:"absolute",top:'0px'}} src="/Images/offline.svg" alt="Offline Vector" />
        <img className="front-img" src="/Images/landpagemob.png" alt="" />
        <br />
        <div className="Front-Page-btn-container">
             <button onClick={()=>{GetStarted()}} className="btn-started"> Get Started ! </button>
        </div>
      </div>
    </>
  );
};
}
export default Frontpage;
