
const Order_summary = (props) => {
  const data = props.data;

  return (
    <>
      <div className="container">
      <div
        className="sales-order-summary"
        style={props.data.Status==="Active" ? { color: "#fff" } : { color: "#6c6d73" }}>
        <img
          src="./Images/user.png"
          style={props.data.Status==="Active" ? {} : { opacity: "0.5" }}
          alt=""
        />
        <span className="userName" style={{width:"75px"}}>
          {data.name == null ? data.name : data.name}
        </span>{" "}
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        <span>{data.visits}</span> 
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        <span>
          <div
            style={
              props.data.Status==="Active" ? {} : { opacity: "0.5", "background-color": "grey" }
            }
            className="Box"></div>
        </span>
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        {data.lastDate}
      </div>
        <span >
          </span>
            </div>
    </>
  );
};

export default Order_summary;
