import React from 'react'

function Offline() {
  console.log("Not Found is called.")
  return (
    <div>
      <img style={{width:"70vw",display:"block",margin:"150px auto 0px auto", borderRadius:"15% 10%", boxShadow:"0px 0px 15px #fff"}} src="/Images/offline.svg" alt="" />
      <h3 style={{color : "#fff", alignSelf:"center", width:"max-content", margin:"30px auto 0px auto"}} >You are now Offline ! <br /> Please connnect to internet. <br /> And Try Again.</h3>
          <h3 onClick={()=>{window.history.go(-1)}} style={{color:"#fff",width:"max-content", margin:"20px auto 0px auto", padding:"8px 20px" , border:"2px solid #fff"}} >Reload </h3>
    </div>
  )
}

export default Offline
