import { createContext , useContext } from "react";
import axios from "axios";
import config from "../../utils/config.json";
import { useConnectionContext } from "../APP/connection-logic";

    let Base_url = "";
    if (config.Application_Mode==="Test") {
        Base_url = config["Base_url"];
    } else {
        Base_url = config["Main_url"];
    }

    // Step 1: Create a Context
    const AxiosContext = createContext();

// Step 2: Provide the Context
export function AxiosContextProvider({ children }) {

        const Online = useConnectionContext();
/**
 * 
 * @param {*} url 
 * @param {*} jsonData 
 * @param {*} stateFunc 
 * @returns {res.data | false | "offline"} 
 */
    async function AxiosCall(url, jsonData, stateFunc) {
        if (Online()) {
            const res = await axios.post(Base_url + url, jsonData);
            if (res.data) {
                console.log("Server Response for : ",url," is : ",res);
                if(stateFunc!==undefined){stateFunc(res.data);}
                return res.data;
            } else {
                console.log(res.data);
                return false;
            }
        }else{
            return "offline";
        }

    }

    return (

        <AxiosContext.Provider value={{ 

        AxiosCall      

        }}>

        {children}
        
        </AxiosContext.Provider>
    );

}


export function useAxiosCall() {
    return useContext(AxiosContext).AxiosCall;
  }