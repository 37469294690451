import { useState } from "react";
import "../../Style/CustomerStyle/signup.css";
import { useGetOTP } from "../../Contexts/APP/routing-logic";
import { useSetPhone } from "../../Contexts/Hooks/loginHooks";

const Signup = () => {

  console.log("Signup Page is called.");

  // *************** New Object Start ***************   
  const GetOTP = useGetOTP();
  const setPhone = useSetPhone();

  // *************** New Object End ***************   
  const [numberValue, setNumberValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [numberState, setnumberState] = useState(false);

  const handleNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setNumberValue(value);
      setnumberState(false);
      // Check if the value has less than ten digits
      if (value.toString().length < 10) {
        setErrorMessage(" Please Complete your Phone Number.");
        document.getElementById("Phone").style.outlineColor = "red";
        setnumberState(false);
      } else {
        setnumberState(true);
        setErrorMessage("");
        document.getElementById("Phone").style.outlineColor = "green";
        if (value.toString().length > 10) {
          setNumberValue(e.target.value.slice(0, 10));
        }
      }
    } else {
      setnumberState(false);
      document.getElementById("Phone").style.outlineColor = "red";
      setNumberValue(e.target.value.slice(0, 10));
      setErrorMessage(" Please enter your Phone number.");
    }
  };

  const getOTP = () => {
    if (numberState) {
      setPhone(numberValue);
      GetOTP(numberValue);
      }
  };

  return (
    <>
      <div className="conatiner" style={{width:"min(99vw,390px)",overflowX:"hidden",overflowY:"hidden"}}>
        <h2 className="greetings">Welcome Sir !</h2>
        <p className="topMsg">Please signup to your account</p>
        <div className="phone-input">
          <input
            className="phn_no scrollbar"
            type="number"
            maxLength="10"
            value={numberValue}
            placeholder="Phone number"
            onChange={handleNumberChange}
            id="Phone"
          />
          <br />
          <img src="/Images/indian-flag-64.png" alt="" />
          <div style={{color:"rgb(127,127,127)",fontSize:"1.2rem",margin:"-56px -10px 0px -100px"}} >+91</div>
          <div className="msg-box">
            {errorMessage && (
              <p
                style={{
                  color: "#ff5349",
                }}>
                {errorMessage}
              </p>
            )}
          </div>
        </div>
        <div className="signup-btn1">
          <button style={{display:"block",width:"max-content",padding:"5px 30px",margin:"auto"}} onClick={getOTP} className="btn-otp">
            GET OTP
          </button>
        </div>
        <div className="logo-img">
          <div className="logo">
            <img className="logo1" src="./Images/greekgod-logo.png" alt="" />
            <img className="logo2" src="./Images/barber.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
