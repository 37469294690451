import { createContext, useContext, useState } from "react";
import { getCookie, UpdateCookie } from "./functions/Cookies-and-Hooks";

// Step 1 : Create A Context 

const LoginContext = createContext();


export function LoginStatesProvider({children}) {
    
    const [SalonId, setSalonId] = useState(getCookie("SalonId"));
    UpdateCookie({name:"SalonId",value:SalonId,userType:getCookie("userType")});

    const [Phone, setPhone] = useState(getCookie("Phone"));
    UpdateCookie({name:"Phone",value:Phone,userType:getCookie("userType")});
    
    const [userType, setUserType] = useState(getCookie("userType"));
    UpdateCookie({name:"userType",value:userType,userType:getCookie("userType")});
    
    const [loginKey, setLoginKey ] = useState(getCookie("loginKey"));
    UpdateCookie({name:"loginKey",value:loginKey,userType:getCookie("userType")});
    // Standard loginKeyLength = 227

    const [loginToken, setLoginToken] = useState(getCookie("loginToken"));
    UpdateCookie({name:"loginToken",value:loginToken,userType:getCookie("userType")});
    // Standard loginTokenLength = 248
    
    return(
        <LoginContext.Provider  value={{

            SalonId,
            setSalonId,

            Phone,
            setPhone,

            userType,
            setUserType,

            loginKey,
            setLoginKey,

            loginToken,
            setLoginToken

        }} >
            {children}
        </LoginContext.Provider>
    )
}


export function useLoginContext () {
    return useContext(LoginContext);
}

export function useSalonId () {
    return useContext(LoginContext).SalonId;
}

export function useSetSalonId () {
    return useContext(LoginContext).setSalonId;
}

export function usePhone () {
    return useContext(LoginContext).Phone;
}

export function useSetPhone () {
    return useContext(LoginContext).setPhone;
}

export function useUserType () {
    return useContext(LoginContext).userType;
}

export function useSetUserType () {
    return useContext(LoginContext).setUserType;
}

export function useLoginKey () {
    return useContext(LoginContext).loginKey;
}

export function useSetLoginKey () {
    return useContext(LoginContext).setLoginKey;
}

export function useLoginToken () {
    return useContext(LoginContext).loginToken;
}

export function useSetLoginToken () {
    return useContext(LoginContext).setLoginToken;
}


