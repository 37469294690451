import axios from 'axios'
import React from 'react'
import config from "../../utils/config.json";

const Testing = () => {
    const resetLogin =()=>{
        axios.post(config["Base_url"]+ "/resetLogin",{
     
            loginToken :"",

        })
    }
    const style = {
        color:"#fff",
        fontSize:"1rem",
        userSelect:"all"
    }
  return (
        <>
            <div className="test-container" style={{display:"flex", color:"white"}}>
                <div className="left" style={{margin:"10px"}}>
                    Salon_id
                </div>
                <div className="center" style={{margin:"10px"}}>
                    userType
                </div>
                <div className="right" style={{margin:"10px"}}>
                    loginStatus
                </div>
                <div className="end" style={{margin:"10px",width:"100px"}}>
                    <button style={{padding:"5px 15px"}} onClick={resetLogin}>Reset Login</button>
                </div>
            </div>



            <div style={style}>Masud Alam</div>
            <div style={style}>9062846488</div>
            <br />
            <div style={style}>Anupam Da</div>
            <div style={style}>9062846488</div>
            <br />
            <div style={style}>Arijit Da</div>
            <div style={style}>9062846488</div>
            <br />
            <div style={style}>My Number</div>
            <div style={style}>6289841625</div>
            

        </>
  )
}

export default Testing
