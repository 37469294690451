// export function getMonthName(dateString) {
//   // Split the input string by '-'
//   const parts = dateString.split("-");

//   // Extract the month part (parts[1]) and convert it to a number
//   const monthNumber = parseInt(parts[1], 10);

//   // Create a Date object with the extracted month number
//   const date = new Date(2000, monthNumber - 1, 1);

//   // Get the month name from the Date object
//   const monthName = date.toLocaleString("default", { month: "short" });

//   return monthName;
// }

export function getWeekday(dateString) {
  // Create a Date object with the input date string
  const date = new Date(dateString);

  // Get the weekday abbreviation from the Date object
  const weekdayAbbreviation = date.toLocaleString("en-US", {
    weekday: "short",
  });

  return weekdayAbbreviation;
}

export function getLast7Dates(endDate) {
  const last7Dates = [];
  // Loop to generate the last 7 dates
  for (let i = 6; i >= 0; i--) {
    const date = new Date(endDate);
    date.setDate(endDate.getDate() - i);
    last7Dates.push(date.toISOString().split("T")[0]);
  }
  return last7Dates;
}
export function getMonthName(dateString) {
  // Split the input date string into year and month parts
  const [year, month] = dateString.split("-");

  // Create a Date object with the year and month
  const date = new Date(year, month - 1);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the month index from the Date object and return the corresponding month name
  return monthNames[date.getMonth()];
}


export const zero2 = (e) => {
  if (e < 10 && e >= 1) {
    return `0${e}`;
  } else if (e < 100 && e >= 10) {
    return `${e}`;
  }
}



export function GetMonth(input) {
  // Split the input into year and month parts
  const [year, month] = input.split('-');
  
  // Convert month number to month name abbreviation
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthAbbr = monthNames[parseInt(month) - 1]; // Subtract 1 because arrays are 0-indexed
  
  // Concatenate and return the formatted date
  return `${monthAbbr}-${year.slice(2)}`;
}

// Test case
// console.log(formatDate('2024-05')); // Output: May-24
