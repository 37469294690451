import React, { useEffect, useState } from "react";
import Linechart from "./Tiny-Components/linechart";
import "../../Style/OwnerStyle/Analytics-common.css";
import ListTemplate from "./Tiny-Components/listtemplate";
import {
  useSalesBlock,
  useSalesChart,
  useSalesPerCustomer,
  useSetAllValueByOption,
} from "../../Contexts/APP/Analytics-logic";
import { addComma } from "../../Functions/text-modification-functions";
import { useNavigate } from "react-router-dom";
const Sales_analytics = () => {
  console.log("Sales Component is loaded...");
  const navigate = useNavigate();


  const salesBlock = useSalesBlock();
  const salesChart = useSalesChart();
  const salesPerCustomer = useSalesPerCustomer();
  const SetAllValueByOption = useSetAllValueByOption();
  const [chartType, setChartType] = useState("daily");

  useEffect(() => {
    SetAllValueByOption(chartType);
    console.log(salesBlock,salesBlock);
  }, []);

  const handleChart = () => {
    let selectedType = document.getElementById("get_duration").value;
    setChartType(selectedType);
    SetAllValueByOption(selectedType);
  };
  
  return (
    <>
      <div className="analytics-container">
      <img onClick={()=>{navigate("/store")}} style={{position:"absolute",top:"20px",left:"15px",width:"30px"}} src="/Images/backarrow.png" alt="Back Button" />

        <div className="analytics-title" id="My-id">
          <h2> Sales Analytics  </h2>
        </div>
        <img onClick={()=>{navigate("/admin")}} style={{position:"absolute",top:"25px",right:"15px", height:"25px", width:"25px"}} src="/Images/homelogo1.png" alt="Back Button" />

        <div className="date-month" style={{ visibility: "hidden" }}>
          <select
            // onChange={handleChart}
            id="getLongDuration"
            className="day"
            name="">
            {/* {selectList?.map((e) => {
              return <option value={e}> {e}</option>;
            })} */}
            <option value="daily">Days</option>
            <option value="weekly">Weekly</option>
            <option defaultValue value="monthly">Monthly</option>
          </select>
        </div>

        <div className="analytics-total">
          <div className="sales-analytics-growth-total">
            <p className="sales-analytics-percent" style={{color:salesBlock.SalesGrowth>=0?"green":"red"}} >{salesBlock.SalesGrowth + "%"}</p>
            <p className="sales-analytics-growth">% Growth</p>
          </div>
          <div className="sales-analytics-amount">
            <h1>{"₹"+ addComma(salesBlock.Sales)}</h1>
            <p>{salesBlock.time}</p>
          </div>
        </div>

        <div className="date-month">
          <select
            value={chartType}
            onChange={handleChart}
            id="get_duration"
            className="day"
            name="">
            <option value="daily">Days</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="half-yearly">half yearly</option>
            <option value="yearly">yearly</option>
          </select>
        </div>
        <Linechart
          chart_timeline={salesChart}
        />
        <div className="highlights-container">
          <nav className="highlights-Frame">
            <div className="highlights-title">Order Summary</div>
          </nav>
          <center className="highlights-list" style={{color:"#fff"}}>
            {/* {JSON.stringify(salesPerCustomer)} */}
            {salesPerCustomer?.map((e) => {
              e.Status = "Active";
              return <ListTemplate data={e} />;
            })}
          </center>
        </div>
      </div>
    </>
  );


};
export default Sales_analytics;
