import { useEffect } from "react";
import "../../Style/OwnerStyle/__mycart.css";
import { Link, useNavigate} from "react-router-dom";
import TableRow from "../catalog/cart/tableRow";
import Prompt from "../customer/prompt";
import { useCalculateCart, useOpenPrompt, useRemovableSpecialityItem, useRemoveItemFromList, useRemoveSpecialityItem, useRemoveableCart, useResetRemoveCart, useTogglePrompt } from "../../Contexts/APP/cart-logic";
import { useCart, usePriceTable, useSessionMsg } from "../../Contexts/Hooks/cartHooks"; 
import { useEndSession, useSetCustomerCart } from "../../Contexts/APP/session-logic";
import { useCartId, useEndResUrl, useSessionObj } from "../../Contexts/Hooks/sessionHooks";

const OwnerCart = () => {
  console.log("my Cart is called.");

  const navigate = useNavigate();

  
  const PriceTable = usePriceTable();
  const cart = useCart();
  const CalculateCart = useCalculateCart();
  const RemoveItemFromList = useRemoveItemFromList();
  const TogglePrompt = useTogglePrompt();
  const openPrompt =useOpenPrompt();
  const RemovableCart = useRemoveableCart();
  const ResetRemoveCart = useResetRemoveCart();
  // const sessionmsg = useSessionMsg(); 
  const RemoveSpecialityItem = useRemoveSpecialityItem();
  const RemovableSpecialityItem = useRemovableSpecialityItem();

  const priceListLength = PriceTable.SpecialityPriceList===undefined?0:PriceTable.SpecialityPriceList.length;
  // const SessionStarted = useStartSession();

  const PrintKey = (i,j)=>{
    console.log("key : ",i+" : ",j);
    return j ;
  }
  useEffect(()=>{

    CalculateCart();
  },[cart])
  
  const SetCustomerCart =useSetCustomerCart();
  const cartId = useCartId();

  const endresurl = useEndResUrl();

  const EndSession = useEndSession();

  const sessionData = useSessionObj().SessionsList;



  useEffect(()=>{
    SetCustomerCart();
  },[])

  var  username = "";
  for (const i of sessionData) {
    if(i.Phone ==cartId){
      username = i.Name;
      break;
    }
    
  }


  useEffect(()=>{
    const e = endresurl;
    console.log("end session e",e);
    if(e.status){
      navigate(e.url);
    }
    },[endresurl]);
  

    return (
      <>
      <div className="mycart-container scrollbar">
        <div className="mycart-heading" >
        <div className="Logo">
          <Link to="/home/catalog/hair_care">

        <img src="/Images/Icons/homebtn.png" alt="" />
          </Link>
        </div>
      
      <div className="topbar">

        <h3 className="mycart-username">My cart</h3>
      </div>
     
        </div>
            {/* <span
            style={{textAlign:"right", textDecoration:"underline", margin:"0px 10px 0px 0px ", color: "#830bd9", float:"right"}}> {__CartContext.cart.length===0 && <span onClick={()=>{navigate("/home/catalog/hair_care")}}> Add Products</span>}
            </span> */}
            
        <p className="quantity" style={{ color: "white" }}>
          QUANTITY
        </p>

      <div className="scrollbar" style={{width:"100%",height:"330px",overflowY:"scroll",overflowX:"hidden"}}>
      {cart?.map((item) => {
             return (item.CategoryPrice === undefined ? <TableRow key={PrintKey(1,item.Product_id)} item={item} /> : item.Items.map(item2=>{
              return (<TableRow key={PrintKey(2,item2["S1-id"]+item2.Product_id+Math.floor(Math.random()*10000000))} title={item.Category} item={item2} />)
            }))
          })}
        {cart.length===0 && <img src="/Images/empty-cart.svg" style={{borderRadius:"50%", width:"300px",display:"block", margin:"25px auto 0px auto"}} alt="" />}
        
      </div>
        <div style={{ marginTop: "20px" }}></div>
        <hr style={{ backgroundColor: "white", width: "363px" }} />
        <div className="price-table" style={{maxHeight:"160px",overflowY:"scroll"}}>

          {/* <div> */}
          {
            priceListLength>0&&
            <>
            <div className="key" style={{margin:" 10px 0px -5px 0px"}} > Package{priceListLength>1?"s":null} </div>
            <div className="val" style={{margin:" 10px 0px -5px 10px"}} > Amount</div></>
          }
          {/* </div> */}

{priceListLength!==0 &&

  PriceTable.SpecialityPriceList.map((item)=>{
    return(
    <><div key={Math.floor(Math.random()*10000).toString()} className="key" style={{ marginLeft:"0px",display:"flex",alignItems:"center"}}> <img src="/Images/points.png" style={{width:"15px",marginRight:"5px"}} alt="Points of Category" /> {item.Category}</div>
    <div key={Math.floor(Math.random()*10000).toString()} className="val" id="price">Rs. {parseInt(item.TotalPrice).toFixed(2)}</div></>
    )
  })

}          
          {parseInt(PriceTable.CatalogPrice)!==0&&<><div className="key" > {priceListLength!==0?"Others":"Price"}</div>
          <div className="val" id="price">Rs. {parseInt(PriceTable.CatalogPrice).toFixed(2)}</div></>}
          {/* {priceListLength>0&&<><hr style={{gridColumn:"span 2", border:"1px dashed #fff"}} /></>} */}
          <div className="key">Discount</div>
          <div className="val">Rs. {parseInt(PriceTable.TotalDiscount).toFixed(2)}</div>
          <div className="key">Total</div>
          <div className="val">Rs. {parseInt(PriceTable.TotalPrice).toFixed(2)}</div>
        </div>
        <hr style={{ backgroundColor: "white", width: "363px" }} />
        <div className="navigate-action">
            {/* <button onClick={()=>{ cart.length!==0 &&  !sessionmsg.ProductsAdded  && navigate("/session-started")  }}  */}
            {/* style={cart.length===0 || sessionmsg.ProductsAdded  ?  {opacity:0.5}:{opacity:1}} ><span>Start Session</span></button> */}
          <button onClick={(e)=>{cart.length!==0 && (EndSession() && navigate("/active-sessions"))}} style={cart.length===0 ? {opacity:0.5}:{opacity:1}} ><span>End Session</span></button>
          <button onClick={(e)=>{cart.length!==0 && ( navigate("/home/catalog/hair_care"))}} style={cart.length===0 ? {opacity:0.5}:{opacity:1}} ><span>Add Products </span></button>
        </div>
      </div>
      {
     
      openPrompt &&  <Prompt action={TogglePrompt} 
      remove={ RemovableSpecialityItem === null ? RemoveItemFromList : RemoveSpecialityItem } 
      product={ RemovableSpecialityItem === null ? RemovableCart : RemovableSpecialityItem }
      clear={ResetRemoveCart}
      />
      }
    </>
  );
};

// }
export default OwnerCart;