/**
 * # Possible Functions : 
 * -- Login
 * -- Verify
 * -- ResendOTP
 * -- AddUserName
 * -- StartSession
 * -- ActiveSessions
 * -- EndSession
 * -- SalesAnalytics
 * 
 */

import { createContext, useContext, useState } from "react";
import { UpdateHook, loadHook } from "./functions/localStorage-and-Hooks";


const ServerResContext = createContext();

export function ServerResStatesProvider({children}) {
    
    const [signupRes, setSignupRes] = useState(loadHook({datatype:"Object",storageKey:"signupRes"}));
    UpdateHook({storageKey:"signupRes",stateVar:signupRes});

    const [verifyRes, setVerifyRes] = useState(loadHook({datatype:"Object",storageKey:"verifyRes"}));
    UpdateHook({storageKey:"verifyRes",stateVar:verifyRes});

    const [resendOTPRes, setResendOTPRes] = useState(loadHook({datatype:"Object",storageKey:"resendOTPRes"}));
    UpdateHook({storageKey:"resendOTPRes",stateVar:resendOTPRes});

    const [userNameRes, setUserNameRes] = useState(loadHook({datatype:"Object",storageKey:"userNameRes"}));
    UpdateHook({storageKey:"userNameRes",stateVar:userNameRes});

    const [startSessionRes, setStartSessionRes] = useState(loadHook({datatype:"Object",storageKey:"startSessionRes"}));
    UpdateHook({storageKey:"startSessionRes",stateVar:startSessionRes});

    const [activeSessionsRes, setActiveSessionsRes] = useState(loadHook({datatype:"Object",storageKey:"activeSessionsRes"}));
    UpdateHook({storageKey:"activeSessionsRes",stateVar:activeSessionsRes});

    const [endSessionRes, setEndSessionRes] = useState(loadHook({datatype:"Object",storageKey:"endSessionRes"}));
    UpdateHook({storageKey:"endSessionRes",stateVar:endSessionRes});

    const [salesAnalyticsRes, setSalesAnalyticsRes] = useState(loadHook({datatype:"Object",storageKey:"salesAnalyticsRes"}));
    UpdateHook({storageKey:"salesAnalyticsRes",stateVar:salesAnalyticsRes});

    const [dumpDataRes, setDumpDataRes] = useState(loadHook({datatype:"Object",storageKey:"dumpDataRes"}));
    UpdateHook({storageKey:"dumpDataRes",stateVar:dumpDataRes});

    return (
        <ServerResContext.Provider value={{

            signupRes,
            setSignupRes,
            verifyRes,
            setVerifyRes,
            resendOTPRes,
            setResendOTPRes,
            userNameRes,
            setUserNameRes,
            startSessionRes,
            setStartSessionRes,
            activeSessionsRes,
            setActiveSessionsRes,
            endSessionRes,
            setEndSessionRes,
            salesAnalyticsRes,
            setSalesAnalyticsRes,
            dumpDataRes,
            setDumpDataRes

        }}>

            {children}
        
        </ServerResContext.Provider>
    )

}

export function useServerResStates() {
    return useContext(ServerResContext);
}

export function useSignupRes(){
    return useContext(ServerResContext).signupRes;
}
export function useSetSignupRes(){
    return useContext(ServerResContext).setSignupRes;
}
export function useVerifyRes(){
    return useContext(ServerResContext).verifyRes;
}
export function useSetVerifyRes(){
    return useContext(ServerResContext).setVerifyRes;
}
export function useResendOTPRes(){
    return useContext(ServerResContext).resendOTPRes;
}
export function useSetResendOTPRes(){
    return useContext(ServerResContext).setResendOTPRes;
}
export function useUserNameRes(){
    return useContext(ServerResContext).userNameRes;
}
export function useSetUserNameRes(){
    return useContext(ServerResContext).setUserNameRes;
}
export function useStartSessionRes(){
    return useContext(ServerResContext).startSessionRes;
}
export function useSetStartSessionRes(){
    return useContext(ServerResContext).setStartSessionRes;
}
export function useActiveSessionsRes(){
    return useContext(ServerResContext).activeSessionsRes;
}
export function useSetActiveSessionsRes(){
    return useContext(ServerResContext).setActiveSessionsRes;
}
export function useEndSessionRes(){
    return useContext(ServerResContext).endSessionRes;
}
export function useSetEndSessionRes(){
    return useContext(ServerResContext).setEndSessionRes;
}
export function useSalesAnalyticsRes(){
    return useContext(ServerResContext).salesAnalyticsRes;
}
export function useSetSalesAnalyticsRes(){
    return useContext(ServerResContext).setSalesAnalyticsRes;
}
export function useDumpDataRes () {
    return useContext(ServerResContext).dumpDataRes;
}
export function useSetDumpDataRes () {
    return useContext(ServerResContext).setDumpDataRes;
}
