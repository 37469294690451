 import HTMLReactParser from "html-react-parser";
 const Zero2=(n)=>{
    if (n>0&&n<10) {
        return`0${n}`
    } else {
        return n
    }
 }
 const JSONFormatter = ({ json }) => {
    const renderJSON = () => {
      try {
        // const formattedJSON = JSON.stringify(JSON.parse(json), null, 2);
        const formattedJSON = JSON.stringify(json, null, 2);
        console.log(formattedJSON);
        const lines = formattedJSON.split('\n');
        return lines.map((line, index) => <div className="JSON" key={index}>{Zero2(index+1)}| {HTMLReactParser(line.replaceAll(" ","&nbsp;"))}</div>);
      } catch (error) {
        return <div>Error parsing JSON</div>;
      }
    };
  
    return <div>{renderJSON()}</div>;
  };

  export{JSONFormatter}