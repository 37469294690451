import { useState, useEffect } from "react";
import "../../Style/CustomerStyle/verify.css";
import { useResendOTP, useVerify } from "../../Contexts/APP/login-logic";
import { useSignupRes, useVerifyRes } from "../../Contexts/Hooks/msgHooks";
import { useOTP_Submit } from "../../Contexts/APP/routing-logic";

const VerifyPage = () => {

  console.log("verify Page is called...");

  const ID = (e) => {return document.getElementById(e)};
  const [timer, setTimer] = useState(90);
  const [ShowMsg, setShowMsg]= useState(false);

  const [otpDeliveryRes, setOtpDeliveryRes] = useState(false); 

  const verifyRes = useVerifyRes();
  const signupRes = useSignupRes();
  const VerifyFunc = useOTP_Submit();
  const ResendOTP = useResendOTP();

  
  useEffect(()=>{
  if(timer>0){setTimeout(() => {
    setTimer(timer-1);
  }, 1000);}
  },[timer]);

  useEffect(()=>{
    setTimeout(() => {
      try {
        ID("in0").focus();        
      } catch (error) {console.log(error)} 
    }, 200);
  },[])

  const Reset = ()=>{
    setShowMsg(true);
    const intSet = [ID("in0"), ID("in1"), ID("in2"), ID("in3")];
    intSet.forEach((e)=>{e.value = "";})
    intSet[0].focus();
    setTimeout(()=>{setShowMsg(false);},4500);
  }

useEffect(()=>{
  if (signupRes.loginKey!==null && signupRes.loginKey!==undefined && signupRes.loginKey.length>200 ) {
    setOtpDeliveryRes(true);
    setTimeout(()=>{setOtpDeliveryRes(false);},4500);    
  }
},[signupRes])

useEffect(()=>{
  if (verifyRes.OTP_Verification_Status===false) {
    Reset();
  }
},[verifyRes])

const changePosition = (id)=>{
    const prev = ()=>{
      if(id!=="in0"){ID(id).previousElementSibling.focus();};
    }
    const next = ()=>{
      if(id!=="in3"){ID(id).nextElementSibling.focus();};
    }
  document.getElementById(id).addEventListener('keyup', function(event) {
    // Check if the key pressed is a number (0-9) or special keys like backspace, delete, arrow keys, etc.
    // const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight','Enter'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent the default action (e.g., typing the character)
    }else{
      console.log(id)
      // ID(id).value = ID(id).target.value ; 
        if (event.key==="Backspace") {
          prev();
        } else if(event.key==="ArrowRight") {
          next();
        } else if (event.key==="ArrowLeft") {
          prev();
        } else if (event.key==="Delete") {
          next();
        }else if (event.key==="Enter") {
          verify_OTP();
        }
        else{
          next();
        }
    }
  });
}

  const zero = (e) => {
    if (e <= 9) {
      return `0${e}`;
    } else {
      return e;
    }
  };
  
  const GetResendOTP = () => {
    if (timer<=0) {
      ResendOTP();
    }
    setTimer(120);
  };

  const verify_OTP = () => {
    const intSet = [ID("in0"), ID("in1"), ID("in2"), ID("in3")];
    var OTP = "";
    for (const i of intSet) {
      OTP = OTP + i.value;
    }
    OTP = parseInt(OTP);
    if (!isNaN(OTP) && OTP <= 9999 && OTP >= 1000) {
    VerifyFunc(OTP);
    }else{
      setShowMsg(true);
    }
  };

  return (
    <>
      <div className="card">
        <p className="otp-msg">
          <br />
          We have sent OTP verification code to you. <br /> This code will
          expires in  &nbsp;
          <span>
            <span>{zero(timer)}</span>s
          </span>
          <br /> <br />
        </p>
        <p style={{width:"max-content", margin:"auto"}} > <span style={{color:"#000"}}>.</span>
        {otpDeliveryRes && <span style={{color:"rgb(14, 187, 14)", width:"max-content", margin:"auto"}} > OTP Delivered Successfully ! </span>}
        
        </p>
        <div id="OTP_input" className="input-otp">
          <div className="container">
              <div id="inputs" className="inputs">
                  <input id="in0" className="input" type="text" onKeyDown={changePosition.bind(this,"in0")}
                      inputMode="numeric" maxLength="1" />
                  <input id="in1" className="input" type="text" onKeyDown={changePosition.bind(this,"in1")}
                      inputMode="numeric" maxLength="1" />
                  <input id="in2" className="input" type="text" onKeyDown={changePosition.bind(this,"in2")}
                      inputMode="numeric" maxLength="1" />
                  <input id="in3" className="input" type="text" onKeyDown={changePosition.bind(this,"in3")}
                      inputMode="numeric" maxLength="1" />
              </div>
          </div>
          {ShowMsg && <div style={{color:"orangered", width:"max-content", margin:"auto"}} > Please Enter Correct OTP ! </div>}
        </div>
          {timer===0 && <p>
           Don't receive a code! &nbsp;  &nbsp;
          <button id="timerAction" onClick={GetResendOTP}>
            <u style={{color:"rgb(14, 187, 14)",fontSize:"1rem"}}><>Please Resend</> </u>{" "}
          </button>
        </p>}
        <button onClick={verify_OTP} className="btn-submit">
          Submit
        </button>
      </div>
      </>);
};

export default VerifyPage;

