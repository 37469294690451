/*
-- PreLogin
-- LoggedIn
-- InSession
-- SessionEnded
-- IncompleteSession
*/

import { useContext, createContext } from "react";
import { useSetSessionMsg } from "../Hooks/cartHooks";
import { useLoginContext } from "../Hooks/loginHooks";
import { useAxiosCall } from "../API/AxiosCall";
import { useSetResendOTPRes, useSetSignupRes, useSetUserNameRes, useSetVerifyRes } from "../Hooks/msgHooks";

const LoginLogicContext = createContext();

export function LoginLogicProvider({children}) {

    console.log("---------- Login Logic Provider is called ----------");

    const AxiosCall = useAxiosCall();
    const SetSessionMsg = useSetSessionMsg();


// # ---------- Setting Response Msg Functions ----------
    const setSignupRes = useSetSignupRes();
    const setVerifyRes = useSetVerifyRes();
    const setResendOTPRes = useSetResendOTPRes();
    const setUserNameRes = useSetUserNameRes();

// # ---------- All Login Variables at Once ----------
    const { SalonId, setSalonId, Phone, setPhone, userType, setUserType, loginKey, setLoginKey, loginToken, setLoginToken } = useLoginContext();

    const Login = async (Phone)=> {
        console.log("inside login Function... ",SalonId,Phone);
        let loginObj = {
            Salon_id: SalonId,
            Phone: Phone
        }
        console.log("Login : ", loginObj);

        let res = await AxiosCall("/login", loginObj, setSignupRes);

        if (res.loginKey) {
            setLoginKey(res.loginKey);
        }
        if (res.userType){
            setUserType(res.userType);
        }

        return res;
    }


    const Verify = async (otp) => {
        console.log("inside context verify");
        let jsonData = {
            loginKey: loginKey,
            OTP: otp
        };

        let res = await AxiosCall("/verify", jsonData, setVerifyRes);

        if (res.loginToken) {
            setLoginToken(res.loginToken);
        }

        if (res.OTP_Verification_Status) {
                SetSessionMsg({
                    "Server_Msg": " ",
                    "ProductsAdded": false,
                    "Verify": true
                })
        }

        return res;

    }

    const ResendOTP = async () => {
        let res = await AxiosCall("/resend-otp", {loginKey:loginKey}, setResendOTPRes);
        // setloginKey(res.loginKey);
        // return res;
    }

    const AddUserName = (value) => {
        let obj = {
            loginToken: loginToken,
            username: value
        }
        let res = AxiosCall("/username", obj, setUserNameRes);
    }

    return(
        <LoginLogicContext.Provider value={{
             Login,
             Verify,
             ResendOTP,
             AddUserName
        }}>
            {children}
        </LoginLogicContext.Provider>
    )

}

export function useLoginLogic() {
   return useContext(LoginLogicContext);
}
export function useLogin() {
   return useContext(LoginLogicContext).Login;
}
export function useVerify() {
   return useContext(LoginLogicContext).Verify;
}
export function useResendOTP() {
   return useContext(LoginLogicContext).ResendOTP;
}
export function useAddUserName() {
   return useContext(LoginLogicContext).AddUserName;
}
