// import { useRoutingContext } from "../../Contexts/APP/routing-logic";
import "../../Style/CustomerStyle/LandingPage.css";
import { Link } from "react-router-dom";
// import useRou
function LandingPage() {
    const prompt=false;
    return (
        <div className='' style={{ backgroundColor: "#051103", display: "flex", alignItems: "flex-start", height: "100vh" }}>
            <div style={{ height: "max-content" }}>
              <img style={{width:"10px",height:"10px",opacity:"0",position:"absolute",top:'0px'}} src="/Images/offline.svg" alt="Offline Vector" />
                <h1 style={{ color: "#fff", display: "block", margin: prompt ? "80px auto 0px auto " : "100px auto 0px auto", width: "max-content" }}>Welcome to </h1>
                <h1 style={{ color: "#fff", display: "block", margin: "auto", width: "max-content", marginBottom: prompt ? "20px" : "0px" }}>GreekGod.in</h1>
                {!prompt &&
                    <img style={{ display: "block", margin: "40px auto 0px auto", borderRadius: "30px", width: "min(60vw, 400px)" }}
                        src="/Images/barbar.png" alt="" />}
                <div id="readerContainer" style={{ transition: "0.7s" }}></div>
                {/* <button
                    onClick={() => { window.location.href = "intent://scan/#Intent;scheme=google_lens;package=com.google.ar.lens;end" }}
                    style={{
                        display: "block", margin: prompt ? "-20px auto 0px auto" : "60px auto 0px auto", width: "max-content",
                        backgroundColor: "#fff", borderRadius: "15px", border: "0px solid #fff", position: "relative",
                        fontWeight: "bold", color: "#7f5401", padding: "8px 18px", fontSize: "20px"
                    }}>
                    Click Me
                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<img src="/Images/qrcodescan.svg" style={{ position: "absolute", top: 4, right: 10 }} width={40} alt="" />
                </button> */}
                    <br />
                    <br />
                <Link style={{color:"#fff"}} to={"/store/ZxYOWZDDj8Q0"} > Store 08 Owner : Anupam Da</Link> <br />
                <Link style={{color:"#fff"}} to={"/store/JPgGryknAJxJ"} > Store 09 Owner : Masud Alam</Link> <br />
                <Link style={{color:"#fff"}} to={"/store/UncDTdRfR2FZ"} > Store 10 Owner : Subarna Manna</Link> <br />
                <Link style={{color:"#fff"}} to={"/store/EX1PL0pQVNxI"} > Store 11 Owner : Arijit Midya</Link> <br />
                {/* <Link style={{color:"#fff"}} to={"/admin"} > Admin Page</Link>  */}
                <br />
                {/* <button onClick={()=>{__RouteContext.move()}} >Testing</button> */}

                <img style={{ display: "block", margin: "50px auto 0px auto", width: "min(100vw, 400px)", opacity: 0 }} src="./Images/Enhance Your Confidence..svg" alt="" />
            

            
            </div>
        </div>
    )
}

export default LandingPage