import { createContext, useContext } from "react";
import { useCart, useCartContext, useOptimizedCart, usePriceTable, useSetCart, useSetPriceTable } from "../Hooks/cartHooks";
import { useSetSpecialityCategoryList, useSpecialityCategoryList } from "../Hooks/specialityHooks";
import Catalog from "../../utils/Catalog/Salon-0001-Final-Catalog-1.5.2.json";

const SpecialityLogic = createContext();


export function SpecialityLogicProvider({children}) {

    const AllCartContext = useCartContext(); 
    
    const cart = useCart();
    const setCart = useSetCart();
    const OptimizedCart = useOptimizedCart();
    const setOptimizedCart = AllCartContext.setOptimizedCart;
    const speciality = Catalog.Speciality;
    const SetPriceTable = useSetPriceTable();
    const priceTable = usePriceTable();
    const SetSpecialityCategoryList = useSetSpecialityCategoryList();
    const SpecialityCategoryList = useSpecialityCategoryList();



    const AddAllToCart = (Category_id, CategorySet)=>{
        if(Category_id!==false){
            let newCart = [...cart];
            CategorySet.Qty=1;
            for (const obj of CategorySet.Items) {
                obj.Qty = 1 ;
            }
            newCart.push(CategorySet);
            setCart(newCart);
        AddAllToOptimizedCart(Category_id,CategorySet);
        let list = [...SpecialityCategoryList];
        list.push(Category_id);
        SetSpecialityCategoryList(list);
    }
}


const SetNormalPricing=()=>{

}

const AddAllToOptimizedCart = (Category_id, CategorySet)=>{

    let __CategorySet ;
    for (const i of speciality) {
        if (i.Category_id===Category_id) {
            __CategorySet = i;
            break;
        }
    }
    
    if (Category_id!==false) {
        let newOptimizedCart = [...OptimizedCart];
        let Obj = {
            "S0-id":"S",
            "S1-id":Category_id,
            "Price":__CategorySet.Category_Price,
            "Qty":1,
            "Items":[]
        }

        for (const i of __CategorySet.Items) {
            let obj2 = {
                "free":i.free,
                "L0-id":i["L0-id"],
                "L1-id":i["L1-id"],
                "L2-id":i.Product_id,
            }
            Obj.Items.push(obj2);
        }

        newOptimizedCart.push(Obj);
        setOptimizedCart(newOptimizedCart);
    }
    let tableData = {
        Price: parseInt(priceTable.Price)  +  __CategorySet.Category_Price,
        Discount: parseInt(priceTable.Discount) + 0 ,
        Total: parseInt(priceTable.Total)  +  __CategorySet.Category_Price
    }
    SetPriceTable(tableData);

}




// const RemoveAllFromSpeciality = ()=>{

// }




return(
    <SpecialityLogic.Provider value={{

        AddAllToCart,
        SetNormalPricing,

    }}>
    
    {children}
    
    </SpecialityLogic.Provider>
)


}



export function useSpecialityContext () {
    return useContext(SpecialityLogic);
}


export function useAddAllToCart() {
    return useContext(SpecialityLogic).AddAllToCart;
}


export function useSetNormalPricing () {
    return useContext(SpecialityLogic).SetNormalPricing;
}

