import "../../../Style/CatalogStyle/CatalogScreen.css";
import { useSetPopUpItemsById } from "../../../Contexts/APP/navigation-logic";
  
  const Item = ({ item, open }) => {
        console.log("Item.jsx is called.");
  const SetPopupItemsById = useSetPopUpItemsById();
  const format_Text = (text) => {
    return text.length > 15 ? text.slice(0, 15) + "..." : text;
  };
  return (
    <>
      <>
        {/* <div onClick={()=>{setCategoryState.bind(item.Category_id); open();}}> */}
        <div onClick={()=>{SetPopupItemsById(item.Category_id); open();}}>
          <div id={item.Category_id} key={item.Category_id} className="catalog-screen-grid-item">
              <img className="product-img" srcSet={"/Images/Storage/"+ item.CategoryUrl} alt="Category Products" />
              <div className="Add-product-control">
                  <div className="Add-product-text">
                    <h5>{format_Text(item.Category)}</h5>
                    <h6>Starts from ₹{item.StartsFrom}</h6>
                  </div>
                  <img className="Add-Product-icon" src="/Images/control_point.svg" alt="" />
              </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Item;
