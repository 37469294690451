
export function add(a, b){
    let p = parseInt(a);
    let q = parseInt(b);
    return p + q;
}

export function ExtendedPrice(p, d){
    let a = parseInt(p);
    let b = parseInt(d);
    return (a * 100 / (100 - b));
}

export function DiscountedPrice(p, d){
    let a = parseInt(p);
    let b = parseInt(d);
    let ExtendedPrice = (a * 100 / (100 - b));
    return (ExtendedPrice * (b / 100));
}

export function minus(a, b){
    let p = parseInt(a);
    let q = parseInt(b);
    return p - q;
}

export function mult(a, b){
    let p = parseInt(a);
    let q = parseInt(b);
    return p * q;
}
