import React from 'react'

function NotFound() {
  console.log("Not Found is called.")
  return (
    <div>
      <img style={{width:"80vw",display:"block",margin:"150px auto 0px auto", borderRadius:"15% 10%", boxShadow:"0px 0px 15px #fff"}} src="./Images/404-error-landing-page.avif" alt="" />
      <h1 style={{color : "#fff", "align-self":"center", width:"max-content", margin:"30px auto 0px auto"}} >PAGE NOT FOUND</h1>
          <h3 onClick={()=>{window.history.go(-1)}} style={{color:"#fff",width:"max-content", margin:"20px auto 0px auto", padding:"8px 20px" , border:"2px solid #fff"}} >GO BACK </h3>
    </div>
  )
}

export default NotFound
