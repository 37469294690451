import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Config from "./utils/config.json";
// ContextVariables => cv  
import cv from "./utils/ApplicationVariables.json";


// # ******************** Default Error Pages ******************** 
import NotFound from './components/customer/notFound';
import Offline from './components/customer/offline';
import Testing from './components/testing/testing';
import Console from './components/testing/Console';


// ******************** Customer Side Pages ********************
import LandingPage from './components/customer/LandingPage';
import Frontpage from './components/customer/frontpage';
import Signup from "./components/customer/signup";
import Verify from './components/customer/verify';
import Username from './components/customer/username';
import Impact from './components/customer/impact';
import Mycart from './components/catalog/cart/mycart';
import Groomed from './components/catalog/Groomed';

// // ******************** Owner Side Pages ********************
import Ownerlandpage from './components/Owner/ownerlandpage';
import Store from './components/Owner/store_analytics';
import Sales from './components/Owner/sales_analytics';
import Customer from './components/Owner/customer_analytics';
import ServiceMixAnalytics from './components/Owner/service-mix_analytics';
import Retail from './components/Owner/retail_analytics';
import Active from './components/Owner/ActiveSession';
import CustomerCart from './components/Owner/Ownercart';

// // ******************** Importing JSON Data ********************

// // ******************** Catalog Pages ********************
import TopNavigation from './components/catalog/top-navigation';
import Navigation from "./components/catalog/catalog-navigation";
import CatalogScreen from "./components/catalog/catalogScreen";
import SpecialityNavigation from "./components/catalog/speciality_navigation";
import Packages from "./components/catalog/packages";
import Startsession from './components/customer/startsession';

// ******************** Application Global States Providers ********************
import { CartStatesProvider } from './Contexts/Hooks/cartHooks';
import { LoginStatesProvider } from './Contexts/Hooks/loginHooks';
import { SessionContextProvider } from './Contexts/Hooks/sessionHooks';

import { AxiosContextProvider } from './Contexts/API/AxiosCall';
import { ConnectionContextProvider } from './Contexts/APP/connection-logic';

// ******************** Application Logic Providers ********************
import { SessionLogicProvider } from './Contexts/APP/session-logic';
import { SpecialityLogicProvider } from './Contexts/APP/Speciality-logic';
import { SpecialityContextProvider } from './Contexts/Hooks/specialityHooks';
import { CartLogicProvider } from './Contexts/APP/cart-logic';
import { LoginLogicProvider } from './Contexts/APP/login-logic';
import { RoutingContextProvider } from './Contexts/APP/routing-logic';
import { ServerResStatesProvider } from './Contexts/Hooks/msgHooks';
import { NavigationLogicProvider } from './Contexts/APP/navigation-logic';
import FitAnalyticsData from './components/Owner/Analytics/Fit-Data';
import { AnalyticsLogicProvider } from './Contexts/APP/Analytics-logic';
import { AnalyticsContextProvider } from './Contexts/Hooks/AnalyticsHooks';
// import FacialAnalysis from './components/testing/facial-analysis';
const App = () => {
  return (
    <>
      {Config.Application_Mode === "Test" ?
        <div style={{ scale: "0.6", zIndex: "+3", padding: "8px 18px", backgroundColor: "rgb(14 187 14)", color: "#fff", borderRadius: "10px", position: "absolute", bottom: "2px", right: "-20px" }}>
          <h4> Test Mode : on </h4>
        </div> : null}
      {/*
 // -- ******************* Level-0 [Application Global Variables are Provided Here.] *******************   
*/ }
      <CartStatesProvider>
        <LoginStatesProvider>
          <ServerResStatesProvider>
            <SessionContextProvider>
              <SpecialityContextProvider>
                <AnalyticsContextProvider>
                {/*
 // -- ******************* Level-1 [Application Router And API Calls are Provided Here.] *******************   
*/ }                  <BrowserRouter> {/* Fixed Tag */}
                  <ConnectionContextProvider>
                    <AxiosContextProvider>
                      {/*
 // -- ******************* Level-2 [Main Application Logic are Provided Here.] *******************   
*/ }
                      <LoginLogicProvider>
                        <NavigationLogicProvider>

                          <CartLogicProvider>
                            <SessionLogicProvider>
                              <SpecialityLogicProvider>
                                <AnalyticsLogicProvider>
                                {/*
 // -- ******************* Level-3 [Application Routers And Components are Provided Here.] *******************   
*/ }
                                <RoutingContextProvider>
                                  <Routes>

                                    {/* *********** Common Login Pages *********** */}
                                    <Route path='/' element={<LandingPage />} />
                                    <Route path='/store/:Id' element={<Frontpage />} />
                                    <Route path={cv.Routers.login} element={<Signup />} />
                                    <Route path={cv.Routers.verify} element={<Verify />} />

                                    {/* *********** Customer Pages *********** */}
                                    <Route path={cv.Routers.username} element={<Username />} />
                                    {/* <Route path='facial-analysis' element={<FacialAnalysis/>} /> */}
                                    <Route path={cv.Routers.impact} element={<Impact />} />


                                    {/* *********** Common Catalog Pages *********** */}
                                    <Route path={cv.Routers.home} element={<TopNavigation />}>
                                      <Route path='catalog' element={<Navigation />}>
                                        <Route path={cv.Routers.category.hair_care} element={<CatalogScreen type="Hair_Care" />} />
                                        <Route path={cv.Routers.category.face_care} element={<CatalogScreen type="Face_Care" />} />
                                        <Route path={cv.Routers.category.body_care} element={<CatalogScreen type="Body_Care" />} />
                                      </Route>
                                      <Route path="speciality" element={<SpecialityNavigation />} >
                                        <Route path="packages" element={<Packages type="Speciality" />} />
                                        <Route path="groomed" element={<Groomed />} />
                                      </Route>
                                    </Route>


                                    <Route path={cv.Routers.mycart} element={<Mycart />} />
                                    <Route path='/session-started' element={<Startsession />} />

                                    {/* ******** Owner side Pages******** */}
                                    <Route path='/testing' element={<Testing />} />
                                    <Route path='/admin' element={<Ownerlandpage />} />
                                    <Route path='/active-sessions' element={<Active />} />
                                    <Route path='/customer-cart' element={<CustomerCart />} />
                                    <Route path='/store' element={<Store />} />
                                    <Route path='/sales' element={<Sales />} />
                                    <Route path='/customer' element={<Customer />} />
                                    <Route path='/service' element={<ServiceMixAnalytics />} />
                                    <Route path='/retail' element={<Retail />} />
                                    <Route path='*' element={<NotFound />} />
                                    <Route path='/offline' element={<Offline />} />
                                    <Route path='/add-data' element={<FitAnalyticsData />} />
                                    <Route path='console' element={<Console/>}/>


                                  </Routes>
                                </RoutingContextProvider>
                                {/*
 // -- ******************* Level-3 [Application Routers And Components are Provided Here.] *******************   
*/ }
                                </AnalyticsLogicProvider>
                              </SpecialityLogicProvider>
                            </SessionLogicProvider>
                          </CartLogicProvider>
                        </NavigationLogicProvider>

                      </LoginLogicProvider>
                      {/*
 // -- ******************* Level-2 [Main Application Logic are Provided Here.] *******************   
*/ }
                    </AxiosContextProvider>
                  </ConnectionContextProvider>
                </BrowserRouter>
                {/*
 // -- ******************* Level-1 [Application Router And API Calls are Provided Here.] *******************   
*/ }
                </AnalyticsContextProvider>
              </SpecialityContextProvider>
            </SessionContextProvider>
          </ServerResStatesProvider>
        </LoginStatesProvider>
      </CartStatesProvider>
      {/*
 // -- ******************* Level-0 [Application Global Variables are Provided Here.] *******************   
*/ }
    </>
  )
}

export default App
