// import { Extended_Price } from "../../Functions/price-functions"

const Prompt = (props) => {
  
    const styles= {
        width:"50%",
        backgroundColor:"rgb(20,20,20)",
        textAlign:"center",
        padding:"5px 10px"
    }
    const leftStyle = {
        borderRadius:"0px 0px 0px 20px",
        backgroundColor:"rgb(24,24,24)"
    }
    const rightStyle = {
        borderRadius:"0px 0px 20px 0px"
        
    }

  // Combine styles into one object
  const leftcombinedStyle = {
    ...styles,
    ...leftStyle,
  };


  // Combine styles into one object
  const rightcombinedStyle = {
    ...styles,
    ...rightStyle,
  };

  const Extended_Price =(Price,Discount,Qty) => {
    return (parseInt(Price)*(1+parseInt(Discount)/100)*parseInt(Qty)).toFixed(2);
  };

  return (
   <>
   <div style={{position:"absolute" ,width:"100vw",height:"100vh",zIndex:"+2",backgroundColor:"rgba(0,0,0,0.75)",top:0,left:0}}>
    <div style={{width:"280px",height:"auto",position:"absolute",top:"300px",left:"auto",right:"auto",
     top: "50%", left: "50%", transform: "translate(-50%, -100%)", borderRadius:"20px", backgroundColor:"rgb(34,34,34)",color:"#fff",zIndex:"+3"}}>
        <div style={{padding:"20px 30px",fontSize:"0.9rem"}}>
             Do you want to remove this ?
             <br />
             <br />
             
             {!props.product.hasOwnProperty("remove") &&  props.product.map((item)=>{
                    return(
                        <div className="cart-items-data" style={{fontSize:"0.9rem",margin:"8px 0px 0px 8px"}}>
                        <p >{item.Name}</p>
                        <p style={{marginTop:"5px"}} >Rs.{Extended_Price(item.Price,item.Discount,item.Qty)}/-</p>
                      </div>
                    )
                })
             }
             
            {props.product.hasOwnProperty("remove") && 
            (
                <div className="cart-items-data" style={{fontSize:"0.9rem",margin:"8px 0px 0px 8px"}}>
                <p style={{marginTop:"18px"}}>{props.product.SpecialityItem.Name}</p>
                <p style={{margin:"8px 0px 0px 20px"}} > From ( {props.product.category} ) </p>
              </div>
                  )
              
            }
             
             {props.customer  &&
                   
                        <div className="cart-items-data" >
                        <p >{props.customer.Name}</p>
                        {/* <p >Rs.{Extended_Price(item.Price,item.Discount,item.Qty)}/-</p> */}
                      </div>
             }
        </div>
  
        <div style={{marginTop:"30px",display:"flex",flexDirection:"row"}}>
            <div onClick={()=>{props.action();props.remove()}} style={leftcombinedStyle}><span style={{color:"red"}}>Yes</span></div>
            <div onClick={()=>{props.action();props.clear()}} style={rightcombinedStyle}><span style={{color:"green"}}>No</span></div>
        </div>
  
    </div>
   </div>
   </>
  )
}

export default Prompt
