import React, { createContext, useContext, useState } from 'react';
import { loadHook, UpdateHook } from './functions/localStorage-and-Hooks';

// Step 1: Create a Context
const SessionContext = createContext();

// const [cartId, setCartId] = useState(false);
// const [sessionObj, setSessionObj] = useState(null);
// const [endResUrl, setEndResUrl] = useState({
    // url: "/active-sessions",
    // status: false
// })




// Step 2: Provide the Context
export function SessionContextProvider({ children }) {

  const [cartId, setCartId] = useState(loadHook({ datatype:"String", storageKey:"cartId" }));
  UpdateHook({ storageKey:"cartId", stateVar:cartId })

  const [sessionObj, setSessionObj] = useState(loadHook({ datatype:"Object", storageKey:"sessionObj" }));
  UpdateHook({ storageKey:"sessionObj", stateVar:sessionObj })

  const [endResUrl, setEndResUrl] = useState(loadHook({ datatype:"Object", storageKey:"endResUrl" }));
  UpdateHook({ storageKey:"endResUrl", stateVar:endResUrl })

// ********* SessionMsg *********

// const [sessionmsg, setSessionmsg] = useState(loadHook({ datatype:"Array", storageKey:"sessionmsg" }));
// UpdateHook({ storageKey:"sessionmsg", stateVar:sessionmsg })

  return (

    <SessionContext.Provider value={{ 
        cartId, 
        setCartId,

        sessionObj,
        setSessionObj,

        endResUrl,
        setEndResUrl

        }}>

      {children}
    </SessionContext.Provider>
  );

}

// Step 3: Create a Custom Hook

export function useSessionContext() {
    return useContext(SessionContext);
}

export function useCartId() {
    return useContext(SessionContext).cartId;
}

export function useSessionObj() {
    return useContext(SessionContext).sessionObj;
  }

export function useEndResUrl() {
    return useContext(SessionContext).endResUrl;
  }


  export function useSetCartId() {
    return useContext(SessionContext).setCartId;
}

export function useSetSessionObj() {
    return useContext(SessionContext).setSessionObj;
  }

export function useSetEndResUrl() {
    return useContext(SessionContext).setEndResUrl;
  }







/*
PreLogin
LoggedIn
InSession
SessionEnded
IncompleteSession
*/

