/*
 * currently I am importing this from All-Analytics.json after mapping we will take this from local storage
 */

  import { GetMonth, getWeekday } from "../../Functions/time-functions";
import Analytics from "../../json/All-Analytics.json";
  
  const vKey = (key)=>{
    if (key !== 'customerListByVisit' && key !== 'customerListBySales' && key !== 'Sales' && key !== 'Customer' && key !== 'SalesGrowth' && key !== 'CustomerGrowth' && key !== 'DateWiseSalesData' && key !== 'CustomerWiseSalesData' && key !== 'DateWiseCustomerData') {
      return true ; 
    }else{
      return false ; 
    }
  }
  
  function reverseKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj; // If obj is not an object or is null, return it as is
    }
  
    var reversedObj = {}; // Create a new object to store the reversed keys
    
    Object.keys(obj).reverse().forEach(function(key) {
      reversedObj[key] = reverseKeys(obj[key]); // Recursively reverse nested objects
    });
    
    return reversedObj;
  }


   function CalculateGrowth(baseValue, currentValue) {

    const bv = parseInt(baseValue);
    const cv = parseInt(currentValue);

    if (cv===0) {
        return 0 ;
    }

    return parseFloat(((cv-bv)/bv*100).toFixed(2));
    
}



  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
//   const Weekdays = ["Sun"]



  export const getlast7daysData = ()=>{
    let DataSet = {
        DateWiseSalesData:[],
        CustomerWiseSalesData:[],
        DateWiseCustomerData:[],
        SalesBlock:{
        },
        CustomerBlock:{
        },
        countObj : {
            year:0,
            hY:0,
            qtr:0,
            month:0
        }
    }
    for (const year in Analytics) {
      if (vKey(year)) { DataSet.countObj.year++;
        const yearlyAnalytics = (Analytics[year]);
        for (const halfYear in yearlyAnalytics) {
          if (vKey(halfYear)) {
             DataSet.countObj.hY+=1;
            const halfYearlyAnalytics = (yearlyAnalytics[halfYear]);
            for (const quarter in halfYearlyAnalytics) {
              if (vKey(quarter)) { 
                DataSet.countObj.qtr+=1;
                const quarterlyAnalytics = (halfYearlyAnalytics[quarter]);
                for (const month in quarterlyAnalytics) { 
                    if(vKey(month)){DataSet.countObj.month+=1;}
                    const monthlyAnalytics = quarterlyAnalytics[month];
                    if (monthlyAnalytics.hasOwnProperty("DateWiseSalesData")) {
                      let prevSales = 0 , prevCustomer = 0  ;
                        for (const key in monthlyAnalytics.DateWiseSalesData) {
                                const element = monthlyAnalytics.DateWiseSalesData[key];
                                DataSet.DateWiseSalesData.push({xVal:[getWeekday(key)],yVal:element});
                                DataSet.SalesBlock.time=getWeekday(key);
                                DataSet.SalesBlock.Sales = element ;
                                DataSet.SalesBlock.SalesGrowth = CalculateGrowth(prevSales,element);
                                prevSales = element ;
                              }
                        for (const key in monthlyAnalytics.CustomerWiseSalesData) {
                            const element = monthlyAnalytics.CustomerWiseSalesData[key];
                            DataSet.CustomerWiseSalesData.push({xVal:[key],yVal:element});
                        }
                        for (const key in monthlyAnalytics.DateWiseCustomerData) {
                            const element = monthlyAnalytics.DateWiseCustomerData[key];
                            DataSet.DateWiseCustomerData.push({xVal:[getWeekday(key)],yVal:element});
                            DataSet.Customer = element;
                            DataSet.CustomerBlock.time=getWeekday(key);
                            DataSet.CustomerBlock.Customer = element ;
                            DataSet.CustomerBlock.CustomerGrowth = CalculateGrowth(prevCustomer,element);
                            prevCustomer = element ;
                          }

                        DataSet.customerListByVisit=monthlyAnalytics.customerListByVisit;
                        DataSet.customerListBySales=monthlyAnalytics.customerListBySales;

                    }
                }
              }
            }
          }
        }        
      }
    }

    if(DataSet.CustomerWiseSalesData.length>7){DataSet.CustomerWiseSalesData =  DataSet.CustomerWiseSalesData.slice(DataSet.CustomerWiseSalesData.length-7,DataSet.CustomerWiseSalesData.length);}
    if(DataSet.DateWiseCustomerData.length>7){DataSet.DateWiseCustomerData = DataSet.DateWiseCustomerData.slice(DataSet.DateWiseCustomerData.length-7,DataSet.DateWiseCustomerData.length);}
    if(DataSet.DateWiseSalesData.length>7){DataSet.DateWiseSalesData = DataSet.DateWiseSalesData.slice(DataSet.DateWiseSalesData.length-7,DataSet.DateWiseSalesData.length);}

    return DataSet ; 
    
  }




  export function mapTheData(countObj){

    console.log("countObj: ",countObj);

    let currentCount = {
        year:0,
        hY:0,
        qtr:0,
        month:0
    }

    const blockDataSet = {
        monthly:{},
        quarterly:{},
        halfYearly:{},
        yearly:{}
    }

    const filtered = {
      monthlySales:[],
      quarterlySales:[],
      halfYearlySales:[],
      yearlySales:[],
      monthlyCustomer:[],
      quarterlyCustomer:[],
      halfYearlyCustomer:[],
      yearlyCustomer:[]
    }
    const storePerformanceOverview = {
      monthly:{},
      quarterly:{},
      halfYearly:{},
      yearly:{}

    }


    for (const year in Analytics) {
      if (vKey(year)) {currentCount.year++;

        const yearlyAnalytics = (Analytics[year]);
        if (countObj.year === currentCount.year+1) {
            blockDataSet.yearly = {
                Sales : yearlyAnalytics.Sales,
                SalesGrowth : yearlyAnalytics.SalesGrowth,
                Customer : yearlyAnalytics.Customer,
                CustomerGrowth : yearlyAnalytics.CustomerGrowth,
                customerListByVisit:yearlyAnalytics.customerListByVisit,
                customerListBySales:yearlyAnalytics.customerListBySales,
                time:year
            }
            storePerformanceOverview.yearly= {
              Sales : yearlyAnalytics.Sales,
              SalesGrowth : yearlyAnalytics.SalesGrowth,
              Customer : yearlyAnalytics.Customer,
              CustomerGrowth : yearlyAnalytics.CustomerGrowth
            }
        }

        for (const halfYear in yearlyAnalytics) {
          if(halfYear=="Sales"){filtered.yearlySales.push({xVal:year,yVal:yearlyAnalytics[halfYear]})};
          if(halfYear=="Customer"){filtered.yearlyCustomer.push({xVal:year,yVal:yearlyAnalytics[halfYear]})};
          if (vKey(halfYear)) { currentCount.hY++;
            const halfYearlyAnalytics = (yearlyAnalytics[halfYear]);
            if (countObj.hY === currentCount.hY+1) {
              blockDataSet.halfYearly = {
                  Sales : halfYearlyAnalytics.Sales,
                  SalesGrowth : halfYearlyAnalytics.SalesGrowth,
                  Customer : halfYearlyAnalytics.Customer,
                  CustomerGrowth : halfYearlyAnalytics.CustomerGrowth,
                  customerListByVisit:halfYearlyAnalytics.customerListByVisit,
                  customerListBySales:halfYearlyAnalytics.customerListBySales,
                  time:halfYear
              }
              storePerformanceOverview.halfYearly = {
                Sales : halfYearlyAnalytics.Sales,
                SalesGrowth : halfYearlyAnalytics.SalesGrowth,
                Customer : halfYearlyAnalytics.Customer,
                CustomerGrowth : halfYearlyAnalytics.CustomerGrowth,
            }
          }
          
            for (const quarter in halfYearlyAnalytics) {
              if(quarter=="Sales"){filtered.halfYearlySales.push({xVal:halfYear+"-"+year.slice(2,4),yVal:halfYearlyAnalytics[quarter]})};
              if(quarter=="Customer"){filtered.halfYearlyCustomer.push({xVal:halfYear+"-"+year.slice(2,4),yVal:halfYearlyAnalytics[quarter]})};
              if (vKey(quarter)) { currentCount.qtr++;
                const quarterlyAnalytics = (halfYearlyAnalytics[quarter]);
                if (countObj.qtr === currentCount.qtr+1) {
                  blockDataSet.quarterly = {
                      Sales : quarterlyAnalytics.Sales,
                      SalesGrowth : quarterlyAnalytics.SalesGrowth,
                      Customer : quarterlyAnalytics.Customer,
                      CustomerGrowth : quarterlyAnalytics.CustomerGrowth,
                      customerListByVisit:quarterlyAnalytics.customerListByVisit,
                      customerListBySales:quarterlyAnalytics.customerListBySales,
                      time:quarter
                  }
                  storePerformanceOverview.quarterly = {
                    Sales : quarterlyAnalytics.Sales,
                    SalesGrowth : quarterlyAnalytics.SalesGrowth,
                    Customer : quarterlyAnalytics.Customer,
                    CustomerGrowth : quarterlyAnalytics.CustomerGrowth,
                 
                }

              }
                
                for (const month in quarterlyAnalytics) {
                  if (vKey(month)) {currentCount.month++;
                    const monthlyAnalytics = quarterlyAnalytics[month];

                    if (countObj.month === currentCount.month+1) {
                        console.log("monthlyAnalytics:",monthlyAnalytics);
                        blockDataSet.monthly = {
                            Sales : monthlyAnalytics.Sales,
                            SalesGrowth : monthlyAnalytics.SalesGrowth,
                            Customer : monthlyAnalytics.Customer,
                            CustomerGrowth : monthlyAnalytics.CustomerGrowth,
                            customerListByVisit:monthlyAnalytics.customerListByVisit,
                            customerListBySales:monthlyAnalytics.customerListBySales,
                            time:GetMonth(month)
                        }
                        storePerformanceOverview.monthly = {
                          Sales : monthlyAnalytics.Sales,
                          SalesGrowth : monthlyAnalytics.SalesGrowth,
                          Customer : monthlyAnalytics.Customer,
                          CustomerGrowth : monthlyAnalytics.CustomerGrowth,
                        
                      }

                    }
                    filtered.monthlySales.push({xVal:GetMonth(month),yVal:monthlyAnalytics.Sales});
                    filtered.monthlyCustomer.push({xVal:GetMonth(month),yVal:monthlyAnalytics.Customer});

                  }
                if(month=="Sales"){filtered.quarterlySales.push({xVal:quarter,yVal:quarterlyAnalytics[month]})};
                if(month=="Customer"){filtered.quarterlyCustomer.push({xVal:quarter,yVal:quarterlyAnalytics[month]})};

                }
              }
            }
          }
        }
      }
    }

    if (filtered.yearlySales.length>6) {filtered.yearlySales = filtered.yearlySales.slice(filtered.yearlySales.length-6, filtered.yearlySales.length)}
    if (filtered.halfYearlySales.length>6) {filtered.halfYearlySales = filtered.halfYearlySales.slice(filtered.halfYearlySales.length-6, filtered.halfYearlySales.length)}
    if (filtered.quarterlySales.length>5) {filtered.quarterlySales = filtered.quarterlySales.slice(filtered.quarterlySales.length-5, filtered.quarterlySales.length)}
    if (filtered.monthlySales.length>6) {filtered.monthlySales = filtered.monthlySales.slice(filtered.monthlySales.length-6, filtered.monthlySales.length)}
    if (filtered.monthlyCustomer.length>6) {filtered.monthlyCustomer = filtered.monthlyCustomer.slice(filtered.monthlyCustomer.length-6, filtered.monthlyCustomer.length)}
    if (filtered.quarterlyCustomer.length>5) {filtered.quarterlyCustomer = filtered.quarterlyCustomer.slice(filtered.quarterlyCustomer.length-5, filtered.quarterlyCustomer.length)}
    if (filtered.halfYearlyCustomer.length>6) {filtered.halfYearlyCustomer = filtered.halfYearlyCustomer.slice(filtered.halfYearlyCustomer.length-6, filtered.halfYearlyCustomer.length)}
    if (filtered.yearlyCustomer.length>6) {filtered.yearlyCustomer = filtered.yearlyCustomer.slice(filtered.yearlyCustomer.length-6, filtered.yearlyCustomer.length)}

    localStorage.setItem("key",JSON.stringify({...blockDataSet , ...filtered})) ; 
    // return {...blockDataSet , ...filtered, ...storePerformanceOverview} ; 
    return {...blockDataSet , ...filtered} ; 
  }



































