import { useEffect, useState } from "react";
import { useSaveUserName } from "../../Contexts/APP/routing-logic";
import "../../Style/CustomerStyle/signup.css";
import { Link } from "react-router-dom";
import { validateUsername } from "../../Functions/text-modification-functions";
const Username = () => {
  console.log("Username Page is called.");
  const SaveUserName = useSaveUserName();
  // useEffect(()=>{
  //   let obj = __LoginContext.UpdateName;
  //   if(obj !== undefined){
  //       if(obj.updated){
  //         navigate("/home/catalog/hair_care")
  //       }
  //   }
  // },[__LoginContext.UpdateName])


  const [username,setusername] = useState("");
  const [isvalid,setIsValid] = useState(false);
  const handleUsername = ()=>{
    setusername(document.getElementById("Namex").value);
  }

  useEffect(()=>{
    setIsValid(validateUsername(username));
  },[username])

  return (
    <>
      <div className="conatiner">
        <h2 className="greetings">Welcome Sir !</h2>
        <p>Please signup to your account</p>

        <br />
        <div style={{color:"red",width:"max-content",margin:"auto"}}>
        <span style={{color:"#000"}}>.</span>
        {!isvalid &&<span >  Please Write a valid name. </span>}
           </div>

        <div className="phone-input">
          <input
            className="phn_no"
            type="text"
            maxLength="20"
            value={username}
            placeholder="Enter your name..."
            onChange={handleUsername}
            id="Namex"
          />
          <div className="msg-box">
            <p
              style={{
                color: "#ff5349",
              }}>
              {/* {errorMessage} */}
            </p>
          </div>
        </div>
        <div className="signup-btn1" style={{width:"max-content",marginLeft:"100px"}}>
          <Link to="/Impact" id="link"></Link>
 

          <button
            onClick={()=>{isvalid && SaveUserName(username)}}
            style={{ backgroundColor: "green" }}
            className="btn-otp">
            SAVE
          </button>
           
        </div>
        <div className="logo-img">
          <div className="logo">
            <img className="logo1" src="./Images/greekgod-logo.png" alt="" />
            <img className="logo2" src="./Images/barber.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Username;
