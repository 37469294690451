import React, { useState } from "react";
import Linechart from "./Tiny-Components/linechart";
import "../../Style/OwnerStyle/Analytics-common.css";
import Order from "./Tiny-Components/order_summary";
// import { getCookie } from "../../Contexts/API/cookies/getcookie";

const RetailService_Analytics = (props) => {
  const sales = props.sales;
  const handleChart = (e) => {
    const ID = (e) => {
      return document.getElementById(e);
    };
  };
// let active = true;
let active = false;
// let AddBorder = "3px solid rgb(255, 145, 0)";
let AddBorder = "inset 0 0 0 3px rgb(153, 0, 255)";
// let AddBorder = "inset 0 0 0 3px linear-gradient(45deg, red, green)";
let RemBorder = "inset 0 0 0 3px #000";
  return (
    <>
      <div className="analytics-container">
        <div className="analytics-title" id="My-id">
          <h2> Retail Service Analytics</h2>
        </div>

        <div className="date-month">

          <select
            onChange={handleChart}
            id="get_duration"
            className="day"
            name="">
            <option value="daily">Jan</option>
            <option value="weekly">Feb</option>
            <option defaultValue value="df">
              March
            </option>
          </select>
        <select onChange={handleChart}
            id="get_duration" className="day" name="">
            <option value="daily">All</option>
            <option value="weekly">Hair Care</option>
            <option value="weekly">Face Care</option>
            <option value="weekly">Body Care</option>
          </select>        
          </div>

        <div className="analytics-total">
          <div className="sales-analytics-growth-total">
            <p className="sales-analytics-percent">
              {sales.Analytics.Sales.Sales.Growth + "%"}
            </p>
            <p className="sales-analytics-growth">% Growth</p>
          </div>
          <div className="sales-analytics-amount">
            <h1>{sales.Analytics.Sales.Sales.Amount}</h1>
            <p>January</p>
          </div>
        </div>
        
        <div className="date-month">
          <select
            onChange={handleChart}
            id="get_duration"
            className="day"
            name="">
            <option value="daily">Days</option>
            <option value="weekly">Weekly</option>
            <option defaultValue value="monthly">
              Monthly
            </option>
          </select>
        </div>
        <Linechart chart_timeline={sales.Analytics.Sales.Chart_Data.Sales_data_days} timeline={{ key_x: "Month", key_y: "Amount", k_val: true}} />
        <div className="highlights-container">
            <nav className="highlights-Frame">
                {/* <div className="highlights-title"style={{float:"left",boxShadow:active?AddBorder:RemBorder}}>Top 3 by category</div> */}
                <div className="highlights-title">TOP 3 SKU By Sale</div>
                {/* <div className="highlights-title"style={{float:"right",boxShadow:!active?AddBorder:RemBorder}}>Service Mix</div> */}
            </nav>
              <center className="highlights-list">
                <Order order={sales.Analytics.Sales.Order_Summary} />
                <Order Active={true} />
                <Order Active={true} />
                <Order Active={true} />
                <h3>See All</h3>
              </center>        
          </div>
      </div>
    </>
  );
};

export default RetailService_Analytics;

