import "../../Style/CatalogStyle/navigation.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useCheckAllCookie } from "../../Contexts/APP/routing-logic";
import { useOptimizedCart } from "../../Contexts/Hooks/cartHooks";
import { useUserType } from "../../Contexts/Hooks/loginHooks";

function TopNavigation() {
  console.log("Top Navigation Called.");

  const checkAllCookie = useCheckAllCookie();
  const optimizedCart = useOptimizedCart();
  const  userType = useUserType();
  const navigate = useNavigate();
  
  console.log("optimizedCart : ", optimizedCart.length);

  if (checkAllCookie()) {

    return (
      <>
        <div>
          <div className="Logo">
            {/* <img src="/Images/Icons/logo.png" alt="" /> */}
          </div>
          <nav className="type-navigation">
            <NavLink
              className="type-nav-btn"
              onClick={()=>{setTimeout(()=>{navigate("/home/catalog/hair_care")},200)}}
              id="catalog"
              to="/home/catalog">
              Catalog
            </NavLink>
            <NavLink
              className="type-nav-btn"
              onClick={()=>{setTimeout(()=>{navigate("/home/speciality/packages")},200)}}
              id="special"
              to="/home/speciality">
              Speciality
            </NavLink>
          </nav>
          <div className="cart-count">
            <NavLink to={userType === "SalonAdmin" ? "/customer-cart" : "/Mycart"} className="type-nav-cart">
              <img src="/Images/Icons/cart-50.png" alt="cart" />
            </NavLink>
          </div>
          <span id="cartId" className="cart-cnt">{optimizedCart.length}</span>
          <Outlet />
        </div>
      </>
    );
  }
}
export default TopNavigation;
