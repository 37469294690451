import { useEffect, useState } from "react";
import "../../../Style/OwnerStyle/fitdata.css";
import ContactList from "../../../json/contact-list.json";
import catalog from "../../../utils/Catalog/Salon-0001-Final-Catalog-1.5.2.json";
import { JSONFormatter } from "./jsonFormatter";
import { useAxiosCall } from "../../../Contexts/API/AxiosCall";
import { useDumpDataRes, useSetDumpDataRes } from "../../../Contexts/Hooks/msgHooks";

const ID = e => { return document.getElementById(e)};
const Zero2=(n)=>{
    if (n>0&&n<10) {
        return`0${n}`
    } else {
        return n
    }
 }

export default function FitAnalyticsData() {
    
    const setDumpDataRes = useSetDumpDataRes();
    const dumpDataRes = useDumpDataRes();
    const AxiosCall = useAxiosCall();
    const date = new Date();

    const [Products, SetProducts] = useState(catalog.Hair_Care);
    const [SendJSON,setSendJSON] = useState({
            "Salon_id": "0011",
            "Customer_id": "9876543210",
            "CustomerName": "Aarav Sharma",
            "date": `${date.getFullYear()}-${Zero2(date.getMonth()+1)}-${Zero2(date.getDay())}`
    });
    const [msgFlag,setMsgFlag] = useState(false);
    const [msg,setMsg]=useState({
        msg:"Data Set Failed.",
        color:"red"
    })
    const [Category_id,setCategory_id]=useState("H");
    // const 

    const ChangeProducts = ()=>{
        const CategoryVal = ID("CategoryID").value;
        setCategory_id(CategoryVal);
        switch (CategoryVal) {
            case "H":
                SetProducts(catalog.Hair_Care);
                break;
            case "F":
                SetProducts(catalog.Face_Care);
                break;
            case "B":
                SetProducts(catalog.Body_Care);
                break;
            case "S":
                SetProducts(catalog.Speciality);
                break;
            default:
                break;
        }
    }
    useEffect(()=>{
        setMsgFlag(!msgFlag);
        setTimeout(()=>{setMsgFlag(!msgFlag)},5000);
        if (dumpDataRes.status) {
            setMsg({
                msg:"Data Set Successfully.",
                color:"green"
            })
        } else{
            setMsg({
                msg:"Data Set Failed.",
                color:"red"
            })
        }
    },[dumpDataRes])
    const setSaolnId = ()=>{
        let obj = {...SendJSON};
        obj.Salon_id=ID("SalonId").value;
        setSendJSON(obj);
    }
    const setCustomer = ()=>{
        let obj = {...SendJSON};
        let customer = ID("CustomerID");
        obj.Customer_id=customer.value;
        obj.CustomerName=customer.options[customer.selectedIndex].innerText;

        setSendJSON(obj);
    }
    const setDate = ()=>{
        let obj = {...SendJSON};
        obj.date=ID("DATE").value;
        setSendJSON(obj);
    }

    const AddState = () =>{
        const value = ID("ProductsID").value;
        const Qty = parseInt(ID("Qty").value);
        let obj = {...SendJSON};
        if(obj.Cart===undefined){
            obj.Cart=[]
        }
        if (obj.Cart.some(obj => obj["Item"] === value)) {
            obj.Cart[obj.Cart.findIndex(obj => obj["Item"] === value)].Qty = Qty;
        }else{
            obj.Cart.push({
                Item:value,
                Qty:Qty
            })
        }
        // obj.date=ID("DATE").value;
        setSendJSON(obj);
    }

    const SendData = () =>{
        AxiosCall("/dump-data", SendJSON, setDumpDataRes);
    }



    return(
        <div style={{marginLeft:"30px"}}>

        <div className="Container" style={{color:"#fff",lineHeight:"30px"}}>

            <div>
            <h4>Salon ID</h4>
            <select onChange={setSaolnId} defaultValue={"0011"} name="SalonId" id="SalonId">
                <option value="0008">0008</option>
                <option value="0009">0009</option>
                <option value="0010">0010</option>
                <option value="0011">0011</option>
            </select>
            </div>

            <div style={{marginLeft:"10px"}}>
            <h4>DATE</h4>
            <input onChange={setDate} type="date" id="DATE" defaultValue={`${date.getFullYear()}-${Zero2(date.getMonth()+1)}-${Zero2(date.getDay())}`}  />
            </div>

            <div style={{gridColumn:"span 2"}}>
            <h4>Customer</h4>
            <select defaultValue={ContactList.contacts[0].phone} onChange={setCustomer} name="Customer" id="CustomerID">
            {ContactList.contacts.map(elem=>{
                return(<option key={elem.phone} value={elem.phone}>{elem.name}</option>)
            })}
            </select>


            </div>
            <div>

            <h4 style={{color:"rgb(126, 79, 255)"}}>Quantity</h4>
            <select defaultValue={"1"} name="Quantity" id="Qty">
                {Category_id!=="S"&&<><option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option></>}
                {Category_id==="S"&&<><option value="1">1</option></>}
            </select>
            </div>

            <div style={{marginLeft:"10px"}}>
            <h4>Category</h4>
            <select  defaultValue={"H"} onChange={ChangeProducts} name="Category" id="CategoryID">
                <option value="H" > Hair Care</option>
                <option value="F" > Face Care</option>
                <option value="B" > Body Care</option>
                <option value="S" > Speciality</option>

            </select>
            </div>
            <div></div>
            <div style={{gridColumn:"span 2"}}>
            <h4 style={{color:"rgb(126, 79, 255)"}}> Products </h4>
            <select style={{width:"86vw"}} name="Products" id="ProductsID">
            {Products?.map(categorySet=>{
                return(categorySet.CategoryPrice===undefined ? categorySet.Items?.map(item=>{
                    return(<option value={`${item["L0-id"]}|${item["L1-id"]}|${item.Product_id}`} key={item.Product_id}  > {item.Name} &nbsp;&nbsp; Rs.{item.Price}/- </option>)
                }): <option value={`S|${categorySet.Category_id}`} key={categorySet.Category_id} > {categorySet.Category} &nbsp;&nbsp; Rs. {categorySet.CategoryPrice}/- </option> )
            })}
            </select>
            </div>
        </div>
        
        <button onClick={AddState} style={{backgroundColor:"rgb(126, 79, 255)"}} >Add State</button>
        <button onClick={SendData} style={{backgroundColor:"rgb(0, 132, 58)"}} >Send Data</button>

        <h4>Server JSON &nbsp; &nbsp; {msgFlag&&<span style={{fontSize:"0.8rem", color:msg.color}}> {msg.msg} </span>} </h4>
        <div className="ServerJSONField" style={{width:"72vw",height:"40vh", color:"#000",backgroundColor:"#f4f4f4",borderRadius:"10px"}}>
            {<JSONFormatter json={SendJSON}/>}
        </div>

        </div>
    )
}