import { createContext, useContext, useEffect, useState } from "react";
import { getCookie } from "../Hooks/functions/Cookies-and-Hooks";
import { useCartContext } from "../Hooks/cartHooks";
import { useCartId } from "../Hooks/sessionHooks";
import { useAxiosCall } from "../API/AxiosCall";
import { add, minus, mult, ExtendedPrice, DiscountedPrice } from "./static-functions";
import { Extended_Price } from "../../Functions/price-functions";
import { useSetSpecialityCategoryList, useSpecialityCategoryList } from "../Hooks/specialityHooks";
import Catalog from "../../utils/Catalog/Salon-0001-Final-Catalog-1.5.2.json";

const CartContext = createContext();

const falsyValues = [ null, undefined, "", [], {}, false ];
const Speciality = Catalog.Speciality;

export function CartLogicProvider({children}) {


    /*
     Main:  cart
    -- |____ completeCart [for Rendering]
    -- |____ optimizedCart [for DB storage]
     * 
     >> Note : Complete Cart is just a part of Main Cart Json.
     * 
     [] *************** Optimized Cart High Level Data Structure ***************
     ; [
     ;      {
     ;      "L0-id":"H",    // Also called Category
     ;      "L1-id":"",     // Also called Sub-Category
     ;      "L2-id":"",     // Also called Product 
     ;      "Qty":""        // Also called Quantity
     ;      },
     ;      {
     ;      "L0-id":"H",    // Also called Category
     ;      "L1-id":"",     // Also called Sub-Category
     ;      "L2-id":"",     // Also called Product 
     ;      "Qty":""        // Also called Quantity
     ;      }
     ; ]
     ; 
     */

    //-- cart : Mycart,popupitems
    // Main: optimizedCart : Api call
    // [] priceTable : Mycart
    //-- AddToCart : popupitems
    //-- RemoveItem : Mycart, popupitems


    const { cart, setCart, priceTable, setPriceTable, optimizedCart, setOptimizedCart, sessionmsg, setSessionmsg } = useCartContext();
    const [openPrompt, setOpenPrompt] = useState(false);
    const [removeableCart, setRemoveablecart] = useState([]);
    const [removableSpecialityItem, setRemovableSpecialityItem] = useState(null);
    const Axioscall = useAxiosCall();

    const TogglePrompt = () => {
        setOpenPrompt(!openPrompt);
    }

    // Main: OptimizedCart
    // Process: ************ The Effect of OptimizedCart ************  
    
    // Step: 01 ==> Update the Effect of OptimizedCart 
    useEffect(() => {
        try {
            document.getElementById("cartId").innerHTML = cart.length;
        } catch (e) {}
    }, [cart])

    const SendCartData = () => {
            let obj = {
            loginToken: getCookie("loginToken"),
            products: optimizedCart
            }
            Axioscall("/session-started",obj,setSessionmsg)
        }

    const CartFunc = (cartObj) => {
        if (falsyValues.includes(cartObj.CategoryPrice)) {
        //* cart.push() for local use 
        cartObj["Qty"] = 1;
        let newCart = [...cart];
        // let newCart = Array.from(cart);
        newCart.push(cartObj);
        setCart(newCart);
        // console.log("cart :", newCart);
        //* optimizedcart.push() for api call 
        let obj = {
            "L0-id": cartObj["L0-id"],
            "L1-id": cartObj["L1-id"],
            "L2-id": cartObj.Product_id,
            Price: cartObj.Price,
            Qty: 1
        }
        let newOptimizedCart = [...optimizedCart];
        newOptimizedCart.push(obj);
        setOptimizedCart(newOptimizedCart);
    }

    }



    // add to cart function
    const AddToCart = cartObj => {
        if (falsyValues.includes(cartObj.CategoryPrice)) {            
            if (cart.length === 0) {
                CartFunc(cartObj);
            } else {
                let k = 0;
                // console.log("2nd time : ", cart);
                for (const i of cart) {
                    if (i.Product_id !== cartObj.Product_id) { k++; }
                }
                if (k === cart.length) { CartFunc(cartObj) }
            }
            try { document.getElementById(cartObj.Product_id).innerHTML = '<img src="/Images/RemoveFromCart.svg" alt="" /> </button>'; } catch (e) { }
        }
    }


    const SetSpecialityCategoryList = useSetSpecialityCategoryList();
    const SpecialityCategoryList = useSpecialityCategoryList();

    const SpecialityToCatalog = ()=>{
        let item =  removableSpecialityItem.SpecialityItem;
        console.log("SpecialityToCatalog : ", item);
        const category = item["S1-id"];
        const ProductId = item.Product_id;  

        let catalogList =[];
        let itemset=[];
        for (const i of Speciality) {
            if(i.Category_id===category){
                for (const j of i.Items) {
                    itemset.push(j);
                }
                // itemset = [...i.Items];
            }
        }

        let optimizedList=[];
        itemset.forEach(elem=>{
            if (!cart.some(obj => obj.hasOwnProperty('Product_id') && obj.Product_id === elem.Product_id) && elem.Product_id!==ProductId) {
                let obj = {
                    "L0-id":elem["L0-id"],
                    "L1-id":elem["L1-id"],
                    "L2-id":elem.Product_id,
                    Qty:1
                }
                optimizedList.push(obj);                
            }

        })

        itemset.forEach((elem)=>{
            if (!cart.some(obj => obj.hasOwnProperty('Product_id') && obj.Product_id === elem.Product_id) && elem.Product_id!==ProductId) {
                let obj = {
                    "L0-id":elem["L0-id"],
                    "L1-id":elem["L1-id"],
                    "Product_id":elem.Product_id,
                    "Name":elem.Name,
                    "Price":elem.Price,
                    "ItemsUrl":elem.ItemsUrl,
                    "Discount":elem.Discount,
                    "Ratings":elem.Ratings,
                    "Sales_unit":elem.Sales_unit,
                    "About_Text":elem.About_Text,
                    Qty:1 
                }
                catalogList.push(obj);
            }
        })
        let newCart = [...cart];
        newCart =  newCart.filter(Item=>Item.Category_id!==category);
        newCart = [ ...newCart, ...catalogList ];
        setCart(newCart);

        let newOptimizedCart = [...optimizedCart];
        newOptimizedCart = optimizedCart.filter(Item=>Item["S1-id"]!==category);
        newOptimizedCart = [ ...newOptimizedCart, ...optimizedList ];
        setOptimizedCart(newOptimizedCart);

        let newSpecialityCategory = [...SpecialityCategoryList];
        console.log("SpecialityCategoryList : ",SpecialityCategoryList);
        newSpecialityCategory = newSpecialityCategory.filter(Items=>Items!==category);
        SetSpecialityCategoryList(newSpecialityCategory);
        console.log("All cart set",newCart,newOptimizedCart);
        setRemovableSpecialityItem(null);
    }


    useEffect(()=>{
        if (!falsyValues.includes(removableSpecialityItem) &&removableSpecialityItem.remove) {
            SpecialityToCatalog();
        }
    },[removableSpecialityItem])

    const RemoveSpecialityItem = ()=>{
        let obj = {
            SpecialityItem:removableSpecialityItem.SpecialityItem,
            remove:true
        }
        setRemovableSpecialityItem(obj);
    }
    const AdjustQuantity = (cartObj, { type }) => {
        console.log("AdjustQuantity : ", cartObj);
        if (cartObj.free===undefined) {    
        console.log("AdjustQuantity is called.");
        let newCart = [...cart];
        let newOptimizedCart = [...optimizedCart];

        for (const i of newCart) {
            if (i.Product_id === cartObj.Product_id) {
                let j = newCart.indexOf(i);
                if (type === "increment") {
                    i.Qty = parseInt(i.Qty) + 1;
                    newOptimizedCart[j]["Qty"] = i.Qty;
                }
                else if (type === "decrement") {
                    if (parseInt(i.Qty) > 1) {
                        i.Qty = parseInt(i.Qty) - 1;
                        newOptimizedCart[j]["Qty"] = i.Qty;
                    }
                    else if (parseInt(i.Qty) === 1) {
                        TogglePrompt();
                        let newCart = [...removeableCart];
                        newCart.push(i);
                        setRemoveablecart(newCart);
                    }
                }
                else {
                    return false;
                }
            }
        }
        setCart(newCart);
        setOptimizedCart(newOptimizedCart);
        }else{
            TogglePrompt();
            let category = "";
            for (const i of Speciality) {
                if(i.Category_id===cartObj["S1-id"]){
                    category = i.Category;
                }
            }
            setRemovableSpecialityItem({
                SpecialityItem : cartObj,
                category:category,
                remove:false
            });
        }
}



    const RemoveItem = cartObj => {
        for (const key in cart) {
            let k = parseInt(key);
            if (cart[k].Product_id === cartObj.Product_id) {
                let newCart = [...cart];
                newCart.splice(k, 1);
                setCart(newCart);
                let newOptimizedCart = [...optimizedCart];
                newOptimizedCart.splice(k, 1);
                setOptimizedCart(newOptimizedCart);
                try { document.getElementById(cartObj.Product_id).innerHTML = '<img src="/Images/AddToCart.svg" alt="" /> </button>'; } catch (y) { }
            }
        }
    }

    const RemoveItemFromList = () => {
        for (const i of removeableCart) {
            RemoveItem(i);
        }
        setRemoveablecart([]);
    }

    const ResetRemoveCart = () => {
        setRemoveablecart([]);
    }


    
    const isInCart = (item) => {
        for (const i of cart) {
            if (i.Product_id === item.Product_id) {
                return true;
            }
        }
        return false;
    }

    const ProductState = item => {
        if (isInCart(item)) {
            RemoveItem(item);
        } else { AddToCart(item); }
    }





    const TotalItems = ()=>{
        let i=0;
        for (const j of cart) {
            if (falsyValues.includes(j.CategoryPrice)) {
                i++;
            } else {
                for (const k of j.Items) {
                    i++;
                }
            }
        }
        return i ;
    }

const CalculateCart = () => {

    /**
     * -- First e cart check hobe. 
     * 
     *-- ***************** New Price Table Data Structure ***************** 
     * 
     * tableData = {
     * Speciality:[
     *  {
     *      "Category":"Instagram Ready"
     *      "TotalPrice":2500 // Extended Sum,
     *      "ActualPrice":1500,
     *      "Discount":1000
     *  }
     * ],
     * PriceCatalog:350,
     * DiscountCatalog:100,
     * totalDiscount:1100,
     * TotalPrice:0,
     * TotalItems:0
     * }
     *  
     */

    /*price = 100;
    discount = 30%
    ** output = {price:price* (1+discount/100),discount:price*(discount/100),} 


    ** output = {ExtendedPrice:price*100/(100-discount),discount:ExtendedPrice*(discount/100),}
    ** total :ExtendedPrice - discount
    let tableData = {
        Price: 0,
        SpecialityPriceList:[],
        Discount: 0,
        Total: 0
    }*/

    let tableData = {
        SpecialityPriceList:[],
        CatalogPrice:0,
        CatalogDiscount:0,
        TotalDiscount:0,
        TotalPrice:0,
        TotalItems:TotalItems()
    }

    for (const i of cart) {

        if (falsyValues.includes(i.CategoryPrice)) { // normal Case
            tableData.CatalogPrice = add(tableData.CatalogPrice , mult(ExtendedPrice(i.Price,i.Discount), i.Qty));
            tableData.CatalogDiscount = add(tableData.CatalogDiscount, mult(DiscountedPrice(i.Price, i.Discount), i.Qty));
            tableData.TotalPrice = add(tableData.TotalPrice , mult(i.Price,i.Qty));
        } else {
            tableData.TotalPrice = add(tableData.TotalPrice, i.CategoryPrice);
            let obj = {
                Category:i.Category,
                TotalPrice:0,
                ActualPrice:i.CategoryPrice,
                Discount:0,
                TotalItems:i.Items.length
            }
            let TotalPrice =0 ;
            for (const k of i.Items) {
                TotalPrice = TotalPrice + Extended_Price(k.Price, k.Discount);
            }
            obj.TotalPrice = TotalPrice ;
            obj.Discount = minus(obj.TotalPrice,obj. ActualPrice);
            tableData.TotalDiscount = add(tableData.TotalDiscount, obj.Discount);
            tableData.SpecialityPriceList.push(obj);
            }
        }
    
    tableData.TotalDiscount = add(tableData.TotalDiscount, tableData.CatalogDiscount);
    setPriceTable(tableData);
}

    return(
        <CartContext.Provider value={{

            // cart,
            // setCart,

            // optimizedCart,
            // setOptimizedCart,

            // priceTable,
            // setPriceTable,

            // sessionmsg,
            // setSessionmsg,

            openPrompt,
            TogglePrompt,

            ProductState,
            AddToCart,
            AdjustQuantity,
            RemoveItem,

            removeableCart,
            RemoveItemFromList,
            ResetRemoveCart,

            CalculateCart,
            SendCartData,

            removableSpecialityItem,
            RemoveSpecialityItem,

        }}>

        {children}

        </CartContext.Provider>
    )
}

export function useCartLogic(){
    return useContext(CartContext);
}
export function useSendCartData(){
    return useContext(CartContext).SendCartData;
}
export function useOpenPrompt(){
    return useContext(CartContext).openPrompt;
}
export function useTogglePrompt(){
    return useContext(CartContext).TogglePrompt;
}
export function useProductState(){
    return useContext(CartContext).ProductState;
}
export function useAddToCart(){
    return useContext(CartContext).AddToCart;
}
export function useAdjustQuantity(){
    return useContext(CartContext).AdjustQuantity;
}
export function useRemoveItem(){
    return useContext(CartContext).RemoveItem;
}
export function useRemoveItemFromList(){
    return useContext(CartContext).RemoveItemFromList;
}
export function useRemoveableCart(){
    return useContext(CartContext).removeableCart;
}
export function useResetRemoveCart(){
    return useContext(CartContext).ResetRemoveCart;
}
export function useCalculateCart(){
    return useContext(CartContext).CalculateCart;
}

// export function useSetWillSpecialityRemove() {
//     return useContext(CartContext).setWillSpecialityRemove;
// }

// export function useSetRemovableSpecialityItem(){
//     return useContext(CartContext).setRemovableSpecialityItem;
// }
export function useRemovableSpecialityItem(){
    return useContext(CartContext).removableSpecialityItem;
}

export function useRemoveSpecialityItem(){
   return useContext(CartContext).RemoveSpecialityItem;
}