import { createContext, useContext, useState } from "react";
import { loadHook ,UpdateHook } from "./functions/localStorage-and-Hooks";

// Step 1 : Create A Context 

const AnalyticsContext = createContext();


export function AnalyticsContextProvider({children}) {


    const [Analytics, setAnalytics] = useState(loadHook({ datatype: "Object", storageKey: "Analytics" }));
    UpdateHook({ storageKey: "Analytics", stateVar: Analytics })
  
    const [last7daysDataSet, setlast7daysDataSet] = useState(loadHook({ datatype: "Object", storageKey: "last7daysDataSet" }));
    UpdateHook({ storageKey: "last7daysDataSet", stateVar: last7daysDataSet })
    
    return(
        <AnalyticsContext.Provider  value={{
            Analytics,
            last7daysDataSet,
            setAnalytics,
            setlast7daysDataSet
        }} >
            {children}
        </AnalyticsContext.Provider>
    )
}


export function useAnalyticsContext () {
    return useContext(AnalyticsContext);
}

export function useAnalytics () {
    return useContext(AnalyticsContext).Analytics;
}

export function useSetAnalytics () {
    return useContext(AnalyticsContext).setAnalytics;
}

export function useLast7daysDataSet () {
    return useContext(AnalyticsContext).last7daysDataSet;
}

export function useSetlast7daysDataSet () {
    return useContext(AnalyticsContext).setlast7daysDataSet;
}

