import React, { useEffect, useState } from "react";
import "../../Style/CatalogStyle/CatalogScreen.css";
import { AnimatePresence ,motion } from "framer-motion";

import PopUpScreen from "./popupScreen";
import { useAddAllToCart } from "../../Contexts/APP/Speciality-logic";
import { useSpecialityCategoryList } from "../../Contexts/Hooks/specialityHooks";
import { useGetCategory, usePopupItems, useSetSpecialityItems } from "../../Contexts/APP/navigation-logic";

const Packages = (props) => {
  console.log("Packages are called.");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenAddToCart,setIsopenAddToCart] = useState(false);
  const openPopup = () => { setIsPopupOpen(true);};
  const closePopup = () => { setIsPopupOpen(false);};
  const [Category_id, setCategory_id] = useState(false);
  const SpecialityCategoryList = useSpecialityCategoryList();
  
  const AddAllTocart = useAddAllToCart();

  console.log("SpecialityCategoryList : ",SpecialityCategoryList)

  const SetSpecialityItems = useSetSpecialityItems();
  const popupitems = usePopupItems();
  const getCategory = useGetCategory();
  // eslint-disable-next-line 
  const data =  getCategory(props.type);

  const SetAddToCartBtnState = ()=>{
    if (SpecialityCategoryList.includes(Category_id)) {
      setIsopenAddToCart(false);
    }else{
      setIsopenAddToCart(true);
    }
  }

  useEffect(()=>{
    SetAddToCartBtnState();
  },[SpecialityCategoryList,isPopupOpen])

  function SetPopupState(id){
    setCategory_id(id); 
    SetSpecialityItems(id); 
    SetAddToCartBtnState();
    openPopup();
  }
  console.log("popupitems : ",popupitems)

  const AddToCart = ()=>{
        AddAllTocart(Category_id, popupitems);
  }
    return (
    <>
    <div className="main-container">
      <div  onClick={()=>{SetPopupState("Off-Re")}} className="speciality-grid-item">
        <img className="speciality-image" src="/Images/Speciality-Images/office.jpg" alt="" />
        <div className="Add-product-control">
          <div className="Add-product-text" >
            <h5>Office Ready</h5>
            <h6>Starts from ₹1,500</h6>
          </div>
          <img className="Add-Product-icon" src="/Images/control_point.svg"  alt="" />
        </div>
      </div>
      <div  onClick={()=>{SetPopupState("Des")}} className="speciality-grid-item">
        <img className="speciality-image" src="/Images/Speciality-Images/meeting.jpg" alt="" />
        <div className="Add-product-control">
          <div className="Add-product-text" >
            <h5>Destressor</h5>
            <h6>Starts from ₹3,500</h6>
          </div>
          <img className="Add-Product-icon" src="/Images/control_point.svg"  alt=""  />
        </div>
      </div>

      <div  onClick={()=>{SetPopupState("Ins-Re")}} className="speciality-grid-item">
        <img className="speciality-image" src="/Images/Speciality-Images/insta.jpg" alt="" />
        <div className="Add-product-control">
          <div className="Add-product-text" >
            <h5>Instagram Ready</h5>
            <h6>Starts from ₹1,500</h6>
          </div>
          <img className="Add-Product-icon" src="/Images/control_point.svg"  alt=""  />
        </div>
      </div>

      <div  onClick={()=>{SetPopupState("Out-Re")}} className="speciality-grid-item">
        <img className="speciality-image" src="/Images/Speciality-Images/outdoor.jpg" alt="" />
        <div className="Add-product-control">
          <div className="Add-product-text" >
            <h5>Outdoor Ready</h5>
            <h6>Starts from ₹2,000</h6>
          </div>
          <img className="Add-Product-icon" src="/Images/control_point.svg"  alt=""  />
        </div>
      </div>
    </div>
    <>
        {isPopupOpen&&<div id="popup-parda"></div>}
      <AnimatePresence>
        {isPopupOpen && (
          <motion.div
          initial={{zIndex:+4, opacity: 0, y: "100%" }}
          animate={{ zIndex:+4,opacity: 1, y: "0%" }}
          exit={{zIndex:+4, opacity: 0, y: "100%" }}
          transition={{ duration: 0.5 }}
            className="popup">
            <div id="popup-container">
              <img id="popup-cancel-img" onClick={closePopup} src="/Images/cancel.svg" alt="X" />
              <div className="singleitempopup"> 
              <PopUpScreen Popupdata={popupitems} />
              </div>
              <div className="add-to-cart-btn" >
              <button onClick={()=>{isOpenAddToCart && AddToCart()}} className="type-nav-btn active Speciality-AddToCart" style={isOpenAddToCart?{opacity:1}:{opacity:0.3}}> Add To Cart </button>
              </div>
              <div style={{width:"inherit",height:"30px"}}></div>
              <br />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>

    </>

  );
};

export default Packages;