/*
 * currently I am importing this from All-Analytics.json after mapping we will take this from local storage
 *
 */

import {
  getLast7Dates
} from "../../Functions/time-functions";
import Analytics from "../../json/All-Analytics.json";

import { createContext, useContext, useEffect, useState } from "react";
import { getlast7daysData, mapTheData } from "./generate-insights-logic";
import { useAnalytics, useLast7daysDataSet, useSetAnalytics, useSetlast7daysDataSet } from "../Hooks/AnalyticsHooks";

const AnalyticsLogic = createContext();

export function AnalyticsLogicProvider({ children }) {

  console.log("AnalyticsLogicProvider is called : ");
// console.log("Analytics:",Analytics);
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  //sales analytics page logic
  const [salesBlock, setSalesBlock] = useState({
    SalesGrowth: 0,
    Sales: 0,
    time:0,
  });
  const [salesChart, setSalesChart] = useState([]);
  const [salesPerCustomer, setSalesPerCustomer] = useState([]);
  // const [salesOption,setSalesOption]

  //customer analytics page logic
  const [customerBlock, setCustomerBlock] = useState({
    CstomerGrowth: 0,
    Customer:0,
    time:0
  });

  const [customerChart, setCustomerChart] = useState([]);
  const [customerVisit ,setCustomerVisit] = useState([]);



  const PrintData = () => {
    return "Data";
  };


  const last7daysDataSet =useLast7daysDataSet();
  const setlast7daysDataSet = useSetlast7daysDataSet();
  const Analytics = useAnalytics();
  const setAnalytics = useSetAnalytics();  

  // const [ last7daysDataSet, setLast7daysDataSet ] = useState({
  //   DateWiseSalesData:[]
  // });
  // const [ AllDataSet , setAllDataSet ] = useState({});


useEffect(()=>{
  setlast7daysDataSet(getlast7daysData());
},[])

useEffect(()=>{
  // console.log("last7daysDataSet : ",last7daysDataSet,last7daysDataSet.countObj);
  if(last7daysDataSet.countObj!==undefined){setAnalytics(mapTheData(last7daysDataSet.countObj));}
},[last7daysDataSet])


  function SetAllValueByOption(type) {

  // setlast7daysDataSet(getlast7daysData());
  // if(last7daysDataSet.countObj!==undefined){setAnalytics(mapTheData(last7daysDataSet.countObj));}
    
    console.log("last7daysDataSet:",last7daysDataSet)
    console.log("Analytics:",Analytics);
    
    switch (type) {
      
      case "daily":
            setSalesBlock(last7daysDataSet.SalesBlock); // Done
            setSalesChart(last7daysDataSet.DateWiseSalesData); // Done
            setSalesPerCustomer(last7daysDataSet.customerListBySales);  

            setCustomerBlock(last7daysDataSet.CustomerBlock);  // Done
            setCustomerChart(last7daysDataSet.DateWiseCustomerData);  // Done
            setCustomerVisit(last7daysDataSet.customerListByVisit);  // Done

        break;
      case "monthly":
            setSalesBlock(Analytics.monthly);
            setSalesChart(Analytics.monthlySales);
            setSalesPerCustomer(Analytics.monthly.customerListBySales);

            setCustomerBlock(Analytics.monthly);
            setCustomerChart(Analytics.monthlyCustomer);
            setCustomerVisit(Analytics.monthly.customerListByVisit);

        break;
      case "quarterly":
            setSalesBlock(Analytics.quarterly);
            setSalesChart(Analytics.quarterlySales);
            setSalesPerCustomer(Analytics.quarterly.customerListBySales);

            setCustomerBlock(Analytics.quarterly);
            setCustomerChart(Analytics.quarterlyCustomer);
            setCustomerVisit(Analytics.quarterly.customerListByVisit);

        break;
      case "half-yearly":
            setSalesBlock(Analytics.halfYearly);
            setSalesChart(Analytics.halfYearlySales);
            setSalesPerCustomer(Analytics.halfYearly.customerListBySales);

            setCustomerBlock(Analytics.halfYearly);
            setCustomerChart(Analytics.halfYearlyCustomer);
            setCustomerVisit(Analytics.halfYearly.customerListByVisit);
      
        break;
      case "yearly":
            setSalesBlock(Analytics.yearly);
            setSalesChart(Analytics.yearlySales);
            setSalesPerCustomer(Analytics.yearly.customerListBySales);

            setCustomerBlock(Analytics.yearly);
            setCustomerChart(Analytics.yearlyCustomer);
            setCustomerVisit(Analytics.yearly.customerListByVisit);

        break;
      default:
        // no stategy
        break;
    }
  }


  return (
    <AnalyticsLogic.Provider
      value={{
        PrintData,
        salesBlock,
        salesChart,
        salesPerCustomer,
        customerBlock,
        customerChart,
        customerVisit,
        SetAllValueByOption,
      }}>
      {children}
    </AnalyticsLogic.Provider>
  );
}

export function AnalyticsContext() {
  return useContext(AnalyticsLogic);
}

export function usePrintData() {
  return useContext(AnalyticsLogic).PrintData;
}

export function useSalesBlock() {
  return useContext(AnalyticsLogic).salesBlock;
}

export function useSalesChart() {
  return useContext(AnalyticsLogic).salesChart;
}

export function useSalesPerCustomer() {
  return useContext(AnalyticsLogic).salesPerCustomer;
}

export function useCustomerBlock() {
  return useContext(AnalyticsLogic).customerBlock;
}

export function useCustomerChart() {
  return useContext(AnalyticsLogic).customerChart;
}

export function useCustomerVisit() {
  return useContext(AnalyticsLogic).customerVisit;
}

export function useSetAllValueByOption() {
  return useContext(AnalyticsLogic).SetAllValueByOption;
}