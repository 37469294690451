import { useEffect } from "react";

export function getCookie(name) {
    var cookies = document.cookie.split('; ');

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].split('=');
        if (cookie[0] === name) {
            return decodeURIComponent(cookie[1]);
        }
    }

    return null;
}

function setCookie(name, value, daysToExpire) {
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    var cookieString = name + "=" + encodeURIComponent(value) + "; expires=" + expirationDate.toUTCString() + "; path=/";
    document.cookie = cookieString;
}

function __setCookie(name, value, hours) {
    var expires = "";
    if (hours) {
        var date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function setCookieUptoEndOfTheDay(cookieName, cookieValue) {
    // Get current date
    var currentDate = new Date();

    // Calculate end of day
    var endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

    // Set cookie with expiration time
    document.cookie = cookieName + "=" + cookieValue + "; expires=" + endOfDay.toUTCString() + "; path=/";

}

// Example usage:
// setCookieWithEndOfDay("my_cookie", "example_value");





/* 
 Identity: 
    A Fundamental
        1. Salon_id
        2.Phone 

    B Derivative
        1. loginKey
        2. loginToken
        3. userType
*/
const falsyValues = [null, undefined, ""];

/* ************* Setting Login Details into Cookies *************  */
/**
 * @param {{name:"", value:"", userType:""}} CookieObj 
 * @returns
 */
export function UpdateCookie(CookieObj) {

    /**
     * ********** High Level Data Structure  ********** 
     *  cookieObj = {
     *    "name":"",
     *    "value":"",
     *    "userType":""
     *   } 
     */

    useEffect(()=>{
        if (!falsyValues.includes(CookieObj.value)) {
            
            if (CookieObj.userType==="SalonAdmin") {
                setCookie(CookieObj.name, CookieObj.value, 7);
            } else {
                setCookieUptoEndOfTheDay(CookieObj.name,CookieObj.value);
                // __setCookie(CookieObj.name, CookieObj.value, 6);
            }
        }

    },)

}
