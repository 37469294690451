

import { createContext , useContext } from "react";
import { useNavigate } from "react-router-dom";
    

    // Step 1: Create a Context
    const ConnectionContext = createContext();

    // Step 2: Provide the Context
    export function ConnectionContextProvider({ children }) {

        const navigate = useNavigate();

        const checkOnline = ()=>{
            const online = navigator.onLine;
            console.log("Check onlinne is called...");
            if (online) {
              return true;
            } else {
              navigate("/offline");
              return false;
            }
          }

    return (

        <ConnectionContext.Provider value={{ 

        checkOnline  

        }}>

        {children}
        
        </ConnectionContext.Provider>
    );

}


export function useConnectionContext() {
    return useContext(ConnectionContext).checkOnline;
  }


  