import { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import "../../Style/OwnerStyle/store_analytics.css";
import { useAnalytics } from "../../Contexts/Hooks/AnalyticsHooks";
// import { getCookie } from "../../Contexts/API/cookies/getcookie";

export default function Store_analytics(props) {
  const analytics = useAnalytics();
  const navigate = useNavigate();

    const [dataSet,setDataSet] = useState(
      {
        Sales :0,
        Customer:0,
        SalesGrowth:0,
        CustomerGrowth:0
      }
    )
  

    

 const handleDuration = (e) => {
    const ID = (e) => {
      return document.getElementById(e);
    };

    var selected_value = ID("get_duration").value;
    console.log(selected_value);
    setDataSet({
      Sales :analytics[selected_value].Sales,
      Customer:analytics[selected_value].Customer,
      SalesGrowth:analytics[selected_value].SalesGrowth,
      CustomerGrowth:analytics[selected_value].CustomerGrowth
    })
    
  };
  useEffect(()=>{
    setDataSet({
      Sales :analytics["monthly"].Sales,
      Customer:analytics["monthly"].Customer,
      SalesGrowth:analytics["monthly"].SalesGrowth,
      CustomerGrowth:analytics["monthly"].CustomerGrowth
    })

  },[])
  return (
    <>
      <div className="__SA-store-container">
      <img onClick={()=>{navigate("/admin")}} style={{position:"absolute",top:"2px",left:"-1.5px", width:"30px"}} src="/Images/backarrow.png" alt="Back Button" />
        <h2>Store Performane Analytics</h2>
        <div className="__SA-day-week-month">
          <select
            onChange={handleDuration}
            className="__SA-day"
            name=""
            id="get_duration" style={{fontWeight:"700"}}>
            <option defaultValue value="monthly">
              Monthly
            </option>
            <option value="quarterly">Quarterly</option>
            <option value="halfYearly">Half Yearly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>

        <div className="__SA-perform-analytics">
          <div className="__SA-sales-banner">
            <h4 style={{visibility:"hidden"}}>COMING SOON</h4>
            <h3>Sales</h3>
            <Link id="Sales" to="/sales"></Link>
            <div
              onClick={() => {
                document.getElementById("Sales").click();
              }}
              className="__SA-total-sales">
              <div className="__SA-growth-total">
                <span className="__SA-growth-g">% Growth</span>
                <span className="__SA-percent-p" id="sales_growth" style={{color:dataSet.SalesGrowth>=0?"green":"red"}} >
                  {dataSet.SalesGrowth + "%"}
                </span>
              </div>
              <div className="__SA-store-amount">
                <h1 id="sales_amount">
                  {"₹ " + dataSet.Sales}
                </h1>
              </div>
            </div>
          </div>

          <div className="__SA-sales-banner">
          <h4 style={{visibility:"hidden"}}>COMING SOON</h4>


            <h3>Customer</h3>
            <Link id="customer" to="/customer"></Link>
            <div
              onClick={() => {
                document.getElementById("customer").click();
              }}
              className="__SA-total-sales">
              <div className="__SA-growth-total">
                <span className="__SA-growth-g">% Growth</span>
                <span id="customer_growth" className="__SA-percent-p" style={{color:dataSet.CustomerGrowth>=0?"green":"red"}}>
                  { dataSet.CustomerGrowth + "%"}
                </span>
              </div>
              <div className="__SA-store-amount">
                <h1 id="customer_amount">{dataSet.Customer}</h1>
              </div>
            </div>
          </div>
          <div className="__SA-sales-banner">
          <h4>COMING SOON</h4>

            <h3>Service Mix</h3>
            <Link id="Mix" to="/Mix"></Link>
            <div
              onClick={() => {
                document.getElementById("Mix").click();
              }}
              className="__SA-total-sales" style={{ filter: 'blur(4px)'}}>
              <div className="__SA-growth-total">
                <span className="__SA-growth-g">% Growth</span>
                <span id="service_mix_growth" className="__SA-percent-p">
                  { "%"}
                </span>
              </div>
              <div className="__SA-store-amount">
                <h1 id="service_mix_amount">{"₹ " }</h1>
              </div>
            </div>
          </div>
          <div className="__SA-sales-banner">
          <h4>COMING SOON</h4>

            <h3>Retail Service </h3>
            <Link id="Retail" to="/Retail"></Link>
            <div
              onClick={() => {
                document.getElementById("Retail").click();
              }}
              className="__SA-total-sales" style={{ filter: 'blur(4px)'}}>
              <div className="__SA-growth-total" >
                <span className="__SA-growth-g">% Growth</span>
                <span id="retail_service_growth" className="__SA-percent-p">
                  { "%"}
                </span>
              </div>
              <div className="__SA-store-amount">
                <h1 id="retail_service_amount">
                  {"₹ "}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="__SA-store-image">
          <img src="./Images/question.jpeg" alt="" />
        </div>
      </div>
    </>
  );
// }
}
