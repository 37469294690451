import { useState } from "react";
import "../../Style/CatalogStyle/CatalogScreen.css";
import PopUpScreen from "./popupScreen";
import { motion, AnimatePresence } from "framer-motion";
import Item from "./cart/item";
import { useCheckAllCookie } from "../../Contexts/APP/routing-logic";
import { useGetCategory, usePopupItems } from "../../Contexts/APP/navigation-logic";

const CatalogScreen = (props) => {
  console.log("catalog screen is called.");

  const [isPopupOpen, setIsPopupOpen] = useState(props.open);

  const popupitems = usePopupItems();
  const getCategory = useGetCategory();

  const checkAllCookie = useCheckAllCookie();

  if(checkAllCookie()){

      const openPopup = () => { setIsPopupOpen(true);};
      const closePopup = () => { setIsPopupOpen(false);};

    return (
      <>
        {isPopupOpen && <div id="popup-parda"></div>}
        <div className="main-container">
          {/****** This Section sets : Set_PopUpItemsById *******/}
          {getCategory(props.type).map((item) => (
            <Item key={item.Category_id} item={item} open={openPopup} />
          ))}
          {getCategory(props.type).length === 0 && <img style={{ margin: "100px auto 0px auto", width: "330px", borderRadius: "50%", position: "absolute", top: "40%", left: "50%", translate: "-50% -50%" }} src="/Images/coming-soon.avif" alt="This Section is Coming Soon." />}
          <div style={{ width: "inherit", height: "300px" }}></div>
        </div>
        <AnimatePresence>
          {isPopupOpen && (
            <motion.div
              initial={{ zIndex: +4, opacity: 0, y: "100%" }}
              animate={{ zIndex: +4, opacity: 1, y: "0%" }}
              exit={{ zIndex: +4, opacity: 0, y: "100%" }}
              transition={{ duration: 0.5 }}
              className="popup">
              <div id="popup-container">
                <img id="popup-cancel-img" onClick={closePopup} src="/Images/cancel.svg" alt="X" />
                <div className="singleitempopup">
                  <PopUpScreen Popupdata={popupitems} />
                </div>
                <div style={{ width: "inherit", height: "30px" }}></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
}

export default CatalogScreen;
