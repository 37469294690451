import React from 'react'
import {  usePrintData } from '../../Contexts/APP/Analytics-logic';

function Console() {
    const PrintData = usePrintData();
    return (
    <div style={{color:"#fff"}}>
      {PrintData()}
    </div>
  )
}

export default Console
