import { createContext, useContext, useState } from "react";
import { loadHook, UpdateHook } from "./functions/localStorage-and-Hooks";

    const specialityContext = createContext();


export function SpecialityContextProvider({ children }) {



    const [specialityState, SetSpecialityState] = useState(loadHook({ datatype:"Object", storageKey:"specialityState" }));
    UpdateHook({ storageKey:"specialityState", stateVar:specialityState })

    const [ SpecialityCategoryList, SetSpecialityCategoryList ] = useState(loadHook({ datatype:"Array", storageKey:"SpecialityCategoryList" }));
    UpdateHook({ storageKey:"SpecialityCategoryList", stateVar:SpecialityCategoryList })


    /* ************** High Level Data Structure **************
     *  {
     *      "isSpecialityTaken":true/false,
     *      "Category_id":"",
     *      "Price":"",
     *      "Items":""
     *  }
     * 
     * 
     */

    return (

        <specialityContext.Provider value={{

            specialityState,
            SetSpecialityState,
            SpecialityCategoryList,
            SetSpecialityCategoryList

        }}>

            {children}

        </specialityContext.Provider>

    )

}

export function useSpecialityContext() {
    return useContext(specialityContext);
}


export function useSetSpecialityState() {
    return useContext(specialityContext).SetSpecialityState;
}


export function useSpecialityState() {
    return useContext(specialityContext).specialityState;
}


export function useSpecialityCategoryList() {
    return useContext(specialityContext).SpecialityCategoryList;
}


export function useSetSpecialityCategoryList() {
    return useContext(specialityContext).SetSpecialityCategoryList;
}




// Speciality te 1 ta Product remove hole sob Normal Pricing Show hobe


/***
 * Rs.3500/- (Pack 1 of 4) Free 
 * Ki bhabe korbo ?
 * optimized cart e hoy individual Product thakbe ar noy total speciality thakbe. 
 * speciality'r bhitore add to cart e click korle optimized cart e sob add hoye jabe.
 * jokhon kono item remove button e click hobe then 1st e cart e jotogulo s0,s1 id 
 * ache se gulor product_id gulo ekta array te store hobe ar cart er oei item gulor 
 * extra speciality flags gulo sob sore jabe.
 * ar optimized cart e individual product pricing show hobe.
 * 
 * 
 * 4 te item ache dam 1500 keu bollo amar sudhu 3 te holei hobe. 
 * dokandar agge 4 te sevice dicchilo 1500 takay ekhon 3 te service debe 1500 takay tahole ete dokandar er lav 
 * plus customer er o lav full e nile koto porto and bundle e nile overall kom porche. 
 * both of them are happy.
 *  
 */

// OptimizedCart Data Structure 
// [{ "L0-id": "H", "L1-id": "HCS", "L2-id": "MHC", "Price": 150, "Qty": 1 }]

// [
//     { 
//         "L0-id": "H", 
//         "L1-id": "HCS", 
//         "L2-id": "MHC", 
//         "Price": 150, 
//         "Qty": 1 
//     },
//     {
//         "S0-id":"S",
//         "S1-id":"Off-Re",
//         "Price":1500,
//         "Qty":1,
//         "Items":[
//             {                    
//                 "free": false,
//                 "L0-id": "H",
//                 "L1-id": "HCS",
//                 "L2-id": "MHC",
//             },  
//             {   
//                 "free": false,
//                 "L0-id": "H",
//                 "L1-id": "BeSt",
//                 "L2-id": "SHA",
//             }
//         ]
//     }
// ]

/* ****************** Cart Data Structure ******************  
 * [ 
 *   {
 *  "Product_id": "MHC",
 *  "Name": "Men Hair styling",
 *  "Price": 150,
 *  "ItemsUrl": "anti_dandruff_treatment.png",
 *  "Discount": 50,
 *  "Ratings": 4.3,
 *  "Sales_unit": 49.4,
 *  "About_Text": "\"Elevate your look with a precision men's haircut at our expert hands, blending style and confidence effortlessly.\"",
 *  "L0-id": "H",
 *  "L1-id": "HCS"
 *   },
 *   {
 *  "Product_id": "MHC",
 *  "Name": "Men Hair styling",
 *  "Price": 150,
 *  "ItemsUrl": "anti_dandruff_treatment.png",
 *  "Discount": 50,
 *  "Ratings": 4.3,
 *  "Sales_unit": 49.4,
 *  "About_Text": "\"Elevate your look with a precision men's haircut at our expert hands, blending style and confidence effortlessly.\"",
 *  "L0-id": "H",
 *  "L1-id": "HCS"
 *   },
 * {
 *          "Category_id": "Off-Re",
            "Category": "Office Ready",
            "Category_Price": 1500,
            "Items": [
                {
                    "free": false,
                    "L0-id": "H",
                    "L1-id": "HCS",
                    "Product_id": "MHC",
                    "S1-id": "Off-Re",
                    "S0-id": "S",
                    "Name": "Men Hair styling",
                    "Price":1500,
                    "ItemsUrl": "anti_dandruff_treatment.png",
                    "Discount": 50,
                    "Ratings": 4.3,
                    "Sales_unit": 49.4,
                    "About_Text": "\"Elevate your look with a precision men's haircut at our expert hands, blending style and confidence effortlessly.\""
                },
                {
                    "free": false,
                    "L0-id": "H",
                    "L1-id": "BeSt",
                    "Product_id": "Be-St",
                    "S1-id": "Off-Re",
                    "S0-id": "S",
                    "bind-id": "BS",
                    "Name": "Beard Style",
                    "Price":0,
                    "ItemsUrl": "hair_styling.png",
                    "Discount": 50,
                    "Ratings": 4.3,
                    "Sales_unit": 22.8,
                    "About_Text": "\"Transform your beard into a statement with our expert beard styling, combining skillful craftsmanship and personal flair for a look that suits your unique style.\""
                },
                {
                    "free": false,
                    "L0-id": "H",
                    "L1-id": "BeSt",
                    "Product_id": "SHA",
                    "S1-id": "Off-Re",
                    "S0-id": "S",
                    "bind-id": "BS",
                    "Name": "Shaving",
                    "Price":0,
                    "ItemsUrl": "hair_styling.png",
                    "Discount": 30,
                    "Ratings": 4.3,
                    "Sales_unit": 52.6,
                    "About_Text": "\"Indulge in the art of grooming with our professional shaving services, where precision meets relaxation for a clean, refreshed look.\""
                },
                {
                    "free": false,
                    "L0-id": "F",
                    "L1-id": "FCln",
                    "Product_id": "O3-Vit",
                    "S1-id": "Off-Re",
                    "S0-id": "S",
                    "Name": "O3+ vitamin",
                    "Price":0,
                    "ItemsUrl": "anti_dandruff_treatment.png",
                    "Discount": 30,
                    "Ratings": 4.3,
                    "Sales_unit": 30.6,
                    "About_Text": "Revitalize your skin with O3+ Vitamin, a skincare infusion designed to nourish and enhance your complexion. Immerse yourself in the rejuvenating power of vitamins, leaving your skin with a radiant and healthy glow. Elevate your skincare routine with O3+ Vitamin for a refreshing and revitalizing experience."
                },
                {
                    "free": true,
                    "L0-id": "H",
                    "L1-id": "HMsg",
                    "Product_id": "N-Oil-10",
                    "Name": "Navratna Oil ( 10 min )",
                    "S1-id": "Off-Re",
                    "S0-id": "S",
                    "Price":0,
                    "ItemsUrl": "hair_spa.png",
                    "Discount": 55,
                    "Ratings": 4.4,
                    "Sales_unit": 20.8,
                    "About_Text": "\"Experience the revitalizing benefits of Navratna Oil in just 10 minutes! Our quick and effective treatment is designed to provide a refreshing and invigorating experience for your hair and scalp. Let the unique blend of Ayurvedic herbs and cooling agents nourish, rejuvenate, and energize, leaving your hair with a touch of natural radiance. Elevate your hair care routine with the rejuvenating power of Navratna Oil in just a short 10-minute session.\""
                }
            ]
 *  }
 * ]
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */

































