import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CustomizedLabel
} from "recharts";

const Linechart = ({ chart_timeline }) => {
  const NumberWithK = (number) => {
    if (number >= 1000) {
      return (number / 1000).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  };
  const ReturnAvgVal = (dataArray) =>{
    let sum = 0,arrayLen = dataArray.length;
    for (const i of dataArray) {
      sum = sum + parseInt(i.yVal);
    }
    return Math.floor(sum/arrayLen);
    


  }
  const add_k = (obj) => {
    if(parseInt(ReturnAvgVal(obj)) >= 10000){
      for (const i in obj) {
        obj[i].label = NumberWithK(JSON.parse(obj[i].yVal));
      }
      return obj;

    }
    else{
      
      for (const i in obj) {
        obj[i].label =(JSON.parse(obj[i].yVal));
      }
      return obj;
    }
  };
  const add_id = (obj) => {
    for (const i in obj) {
      obj[i].id = JSON.parse(i) + 1;
    }
    return obj;
  };
  const DataFormater = (number) => {
    if (number > 1000000000) {
      return Math.ceil((number / 1000000000)).toString() + "B";
    } else if (number > 1000000) {
      return Math.ceil((number / 1000000)).toString() + "M";
    } else if (number > 1000) {
      return Math.ceil((number / 1000)).toString() + "K";
    } else {
      return number.toString();
    }
  };
  const getMaxValue = (e) => {
    const dataSet = chart_timeline ;
    const maxValue = Math.max(...dataSet.map(obj => obj.yVal));
    console.log(maxValue);
    return maxValue.toFixed(2);
  };

  const __chart_timeline = add_k(add_id(chart_timeline));
  console.log("__chart_timeline", __chart_timeline);
  return (
    <>
      <div className="chart-container">
        <ResponsiveContainer
          width={"110%"}
          height={"50%"}
          style={{ margin: "20px 0px 0px -28px" }}
          aspect={3}>
          <BarChart
            data={__chart_timeline}
            margin={{ top: 20, left: 0, right: 0 }}
            width={300}
            height={200}>
            <XAxis
              dataKey={"xVal"}
              stroke="#FFFFFF"
              axisLine={{ stroke: "none" }}
              tick={{ fontSize: 15 ,fontWeight:500}}

            />
            <YAxis
              dataKey={"yVal"}
              type="number"
              domain={[0, getMaxValue()]}
              tickFormatter={DataFormater}
              
              allowDecimals={false}
              
              tick={{ fill: "White" , fontSize: 17,fontWeight:500}}
              tickCount={4}
              allowDataOverflow={false}
              
            />
            <Bar
            
              dataKey={"yVal"}
              tickFormatter={DataFormater}
              
              
              fill="#FFFFFF"
              barSize={16}

              radius={10}>
              <LabelList
                // dataKey={"yVal"}
                dataKey={"label"}
                position="top"
                fill="white"
              fontSize={13}
                
              />
            </Bar>

          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Linechart;
