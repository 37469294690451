import '../../Style/CatalogStyle/navigation.css';
import {NavLink,Outlet} from 'react-router-dom';

const Speciality = () => {
    
    return (
        <>        
        <nav className='catalog-nav'>
            <NavLink className='catalog-nav-btn' id='packages' to='/home/speciality/packages'>Packages</NavLink>            
            <NavLink className='catalog-nav-btn' id='groomed'  to='/home/speciality/groomed'>Groomed</NavLink>            
        </nav>
        <Outlet/>
        </>
  )
}

export default Speciality
