import { useCart, useSessionMsg } from "../../../Contexts/Hooks/cartHooks";
import { useAdjustQuantity } from "../../../Contexts/APP/cart-logic";
import { useEffect } from "react";
const Extended_Price =(Price,Discount,Qty) => {
    return Math.floor(parseInt(Price)*100/(100-parseInt(Discount))*parseInt(Qty)).toFixed(2);
  };
  // price*100/(100-discount)
  function TableRow(props) {
    // const cart = useCart();
    // useEffect(()=>{

    // },[cart])
    const isSpecial = props.item["S0-id"] === "S";
    console.log("inside table row",props.item.free);
    const sessionmsg = useSessionMsg();
    const AdjustQuantity = useAdjustQuantity();
    // const 
    return (
    <div>
    <div className="cart-items">
      <div className="cart-items-icon" >
      <img src="/Images/Icons/product.png" alt="" />
      </div>
      <div className="cart-items-data-block">
      <div className="cart-items-data" key={props.item.Product_id}>
        <p style={{fontSize:"0.8rem"}} >{props.item.Name}</p>
        <p style={{fontSize:"0.8rem",marginTop:"2px"}} > {!isSpecial && `Rs. ${Extended_Price(props.item.Price,props.item.Discount,props.item.Qty)}/-`} {isSpecial &&<>{props.title}  </>}</p>
       {isSpecial && <img className="combo" style={{width:"35px"}} src="/Images/combologo.png" alt="" />}
       {/* { props.item.free &&  <img className="free" style={{width:"30px"}} src="/Images/freetag.png" alt="" />} */}
       { props.item.free && <span style={{color:"rgb(14, 187, 14)",position:"absolute",right:"24px",bottom:"-3.2px"}}>Free</span>}
      </div>
      </div>
      <div className="increase-decrease-btn">
          <button onClick={()=>{sessionmsg.ProductsAdded === false &&  props.item.free === undefined  && AdjustQuantity(props.item,{type:"increment"})}}> 
          <img style={sessionmsg.ProductsAdded || props.item.free !== undefined ? {opacity:"0.5"} : {opacity:"1"} } src="/Images/add.png" alt="" /> 
          </button>
          <p style={{ color: "white" }}>{props.item.Qty}</p>
          {/* <button onClick={()=>{sessionmsg.ProductsAdded === false && AdjustQuantity(props.item,{type:"decrement"})}}> */}
          <button onClick={()=>{ AdjustQuantity(props.item,{type:"decrement"})}}>
          <img style={sessionmsg.ProductsAdded  ? {opacity:"0.5"} : {opacity:"1"} } src="/Images/rem.png" alt="" /> 
          </button>            
        </div>
      <div
        className="cart-items-checked">
        <img src="/Images/check_circle.svg" alt="" />
      </div>
    </div>
  </div>
  )
}
export default TableRow
