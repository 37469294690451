import React, { useState,useEffect } from "react";
import Linechart from "./Tiny-Components/linechart";
import "../../Style/OwnerStyle/Analytics-common.css";
import Order from "./Tiny-Components/order_summary";
import {
  useCustomerBlock,
  useCustomerChart,
  useCustomerVisit,
  useSetAllValueByOption,
} from "../../Contexts/APP/Analytics-logic";
import { useNavigate } from "react-router-dom";

const Customer_Analytics = () => {
  const navigate = useNavigate();
  const customerBlock = useCustomerBlock();
  const customerChart = useCustomerChart();
  const customerVisit = useCustomerVisit();
  const SetAllValueByOption = useSetAllValueByOption();
  
  const [chartType, setChartType] = useState("daily");

  useEffect(() => {
    SetAllValueByOption(chartType);
  }, []);

  const handleChart = () => {
    let selectedType = document.getElementById("get_duration").value;
    setChartType(selectedType);
    SetAllValueByOption(selectedType);
  };

  return (
    <>
      <div className="analytics-container">
      <img onClick={()=>{navigate("/store")}} style={{position:"absolute",top:"20px",left:"15px",width:"30px"}} src="/Images/backarrow.png" alt="Back Button" />


        <div className="analytics-title" id="My-id">
          <h2>Customer Analytics</h2>
        </div>
        <img onClick={()=>{navigate("/admin")}} style={{position:"absolute",top:"25px",right:"15px", height:"25px", width:"25px"}} src="/Images/homelogo1.png" alt="Back Button" />


        <div className="date-month">
        {/* <select style={{float:"left",marginLeft:"10px",opacity:"0.5"}}
            onChange={handleChart}
            // id="get_duration"
            className="day"
            name="">
            <option value="daily">2023</option>
            <option value="weekly">2024</option>
            <option defaultValue value="monthly">
              2025
            </option>
          </select> */}
          {/* <select
            onChange={handleChart}
            // id="get_duration"
            className="day"
            name="">
            <option value="daily">Jan</option>
            <option value="weekly">Feb</option>
            <option defaultValue value="monthly">
              Monthly
            </option>
          </select> */}
        </div>

        <div className="analytics-total">
          <div className="sales-analytics-growth-total">
            <p className="sales-analytics-percent" style={{color:customerBlock.CustomerGrowth>=0?"green":"red"}} >
              {customerBlock.CustomerGrowth + "%"}
            </p>
            <p className="sales-analytics-growth">% Growth</p>
          </div>
          <div className="sales-analytics-amount">
            <h1>{customerBlock.Customer}</h1>
            <p>{customerBlock.time}</p>
          </div>
        </div>
        
        <div className="date-month">
          <select
            onChange={handleChart}
            id="get_duration"
            className="day"
            name="">
            <option value="daily">Days</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="half-yearly">half yearly</option>
            <option value="yearly">yearly</option>
          </select>
        </div>
        <Linechart chart_timeline={customerChart}  />
        <div className="highlights-container">
            <nav className="highlights-Frame">
                <div className="highlights-title">Customer's By No of Visit</div>
            </nav>
              <center className="highlights-list">
              {customerVisit?.map((e) => {
              e.Status = "Active";
              return <Order data={e} />;
            })}
                {/* <h3>See All</h3> */}
              </center>                    
          </div>
      </div>
    </>
  );
// };

}
export default Customer_Analytics;

