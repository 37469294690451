
import {createContext, useContext, useState } from "react";

import Catalog from "../../utils/Catalog/Salon-0001-Final-Catalog-1.5.2.json";

import { useCart } from "../Hooks/cartHooks";


const NavigationContext = createContext();


export function NavigationLogicProvider({children}){


    const [categoryitems, setCategoryItems] = useState([]);

    const [popupItems, setPopupitems] = useState([]);

    const cart = useCart();

    const SetPopUpItemsById = id => {
        // id means the Category_id
        console.log("SetPopUpItemsById is called...", id);
        for (const i of categoryitems) {
            if (i.Category_id === id) {
                try {
                    for (const j of i.Items) {
                        for (const k of cart) {
                            if (k.Product_id === j.Product_id) {
                                j.taken = true;
                            } else {
                                j.taken = false;
                            }
                        }
                    }
                    setPopupitems(i.Items);
                } catch (e) {
                    setPopupitems(i.Items);
                }
            }
        }
    }



    const SetSpecialityItems = (id)=>{
        for (const i of Catalog.Speciality) {
            if (i.Category_id===id) {
                setPopupitems(i);
            }
        }
    }

    const StartsFrom = (arr) => {
        const sortedArray = arr.sort((a, b) => a.Price - b.Price);
        return sortedArray[0].Price;
    }

    // const createCategoryList = () => {
    //     console.log("Note : Inside Create Catagory List.")
    //     console.log(categoryitems);
    //     let catagoryList = [];
    //     for (const i of categoryitems) {
    //         let obj = {
    //             "Category_id": i.Category_id,
    //             "Category": i.Category,
    //             "CategoryUrl": i.CategoryUrl,
    //             "StartsFrom": StartsFrom(i.Items)
    //         }
    //         catagoryList.push(obj);
    //     }
    //     setCatagoryList(catagoryList);
    //     console.log(catagoryList);
    // }

    const GetCategory = type => {
        // console.log("type = ", type);
        let newlist = [...Catalog[type]];
        // console.log(newlist);
        for (const i of newlist) {
            i["StartsFrom"] = StartsFrom(i.Items);
        }
        setTimeout(() => {
            setCategoryItems(Catalog[type]);
        }, 200); 
        return newlist;
    }

    //  setCatalogState (type) : sets hair care ....
    //  setCategoryItems : sets the items inside hair care 
    // SetPopUpItemsById (id) : from the mutiple sub-category selects the Category based on given id and items array 

    return(
        <NavigationContext.Provider value={{

            popupItems,
            SetPopUpItemsById,
            GetCategory,
            SetSpecialityItems

        }}>
        {children}
        </NavigationContext.Provider>
    )

}

export function useNavigationContext() {
    return useContext(NavigationContext);
}

export function usePopupItems() {
    return useContext(NavigationContext).popupItems;
}

export function useSetPopUpItemsById() {
    return useContext(NavigationContext).SetPopUpItemsById;
}

export function useGetCategory() {
    return useContext(NavigationContext).GetCategory;
}

export function useSetSpecialityItems() {
    return useContext(NavigationContext).SetSpecialityItems;
}

