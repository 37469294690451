import { createContext, useContext, useState, useEffect } from "react";
import { useAxiosCall } from "../API/AxiosCall";
import { useSessionContext } from "../Hooks/sessionHooks";
import { useCart, useCartContext } from "../Hooks/cartHooks";
import { useOptimizedCart } from "../Hooks/cartHooks";
import axios from "axios";
import Catalog from "../../utils/Catalog/Salon-0001-Final-Catalog-1.5.2.json";
import config from "../../utils/config.json";
import { useLoginToken } from "../Hooks/loginHooks";
import {
  useEndSessionRes,
  useSetActiveSessionsRes,
  useSetEndSessionRes,
} from "../Hooks/msgHooks";

// Step 1: Create a Context
const SessionLogicContext = createContext();

// Step 2: Provide the Context
export function SessionLogicProvider({ children }) {
  const AxiosCall = useAxiosCall();
  const __CartContext = useCartContext();

  const cart = useCart();
  const optimizedCart = useOptimizedCart();

  // const [Sessions, setSesions] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);

  let url = "";
  if (config.Application_Mode === "Test") {
    url = config["Base_url"];
  } else {
    url = config["Main_url"];
  }

  // const [endResUrl, setEndResUrl] = useState({
  // url: "/active-sessions",
  // status: false
  // })

  const {
    cartId,
    setCartId,
    sessionObj,
    setSessionObj,
    endResUrl,
    setEndResUrl,
  } = useSessionContext();
  const customercart = sessionObj.CartData;
  const loginToken = useLoginToken();

  const SetActiveSessionsRes = useSetActiveSessionsRes();
  const setEndSessionRes = useSetEndSessionRes();
  const endSessionRes = useEndSessionRes();

  useEffect(() => {
    GetSession();
  }, [endSessionRes]);

  const TogglePrompt = () => {
    setOpenPrompt(!openPrompt);
  };

  useEffect(() => {
    console.log("Cart Data : ", sessionObj.CartData);
    if (sessionObj.CartData !== undefined && sessionObj.CartData !== false) {
      for (const obj of sessionObj.CartData) {
        if (parseInt(obj.Phone) === parseInt(cartId)) {
          obj.Product_list = optimizedCart;
        }
      }
      let newSessionobj = { ...sessionObj };
      setSessionObj(newSessionobj);
    }
  }, [optimizedCart]);

  const GetSession = async () => {
    console.log("get session is called....");
    let obj = {
      loginToken: loginToken,
    };

    let res = await AxiosCall("/active-sessions", obj, SetActiveSessionsRes);
    if (res) {
      console.log(res, res);
      setSessionObj(res);
    }
  };

  const findCart = (catalog, product) => {
    for (const category of catalog) {
      if (product["L1-id"] === category.Category_id) {
        for (const item of category.Items) {
          if (item.Product_id === product["L2-id"]) {
            item.Qty = product.Qty;
            return item;
          }
        }
      }
    }
  };

  const Speciality = Catalog.Speciality;
  const findSpeciality = (item)=>{
    for (const i of Speciality) {
        
        if(item["S1-id"]===i.Category_id){
            i.Qty=1;
            for (const j of i.Items) {
                j.Qty=1;
            }
            return i;
        }
    }
  }
  /**
   * [{"Qty":2,"L0-id":"H","L2-id":"MHC","L1-id":"HCS","Price":150},{"L2-id":"O3-Vit","L1-id":"FCln","L0-id":"F","Qty":1},{"S0-id":"S","Qty":1,"Items":[{"L2-id":"Be-St","L1-id":"BeSt","L0-id":"H","free":false},{"free":false,"L1-id":"HCS","L0-id":"H","L2-id":"MHC"},{"L1-id":"HMsg","free":true,"L0-id":"H","L2-id":"N-Oil-10"},{"free":false,"L2-id":"O3-Vit","L1-id":"FCln","L0-id":"F"}],"S1-id":"Off-Re"},{"Items":[{"L2-id":"Be-St","free":true,"L1-id":"BeSt","L0-id":"H"},{"free":true,"L2-id":"MHC","L1-id":"HCS","L0-id":"H"},{"L2-id":"L-MHSp-S","L0-id":"H","free":false,"L1-id":"HSp"},{"L0-id":"F","L2-id":"Ozo-Glw4","L1-id":"Fac","free":false}],"S1-id":"Des","Qty":1,"S0-id":"S"}]
   */
/**
 * [
  {
    "Qty": 2,
    "L0-id": "H",
    "L2-id": "MHC",
    "L1-id": "HCS",
    "Price": 150
  },
  {
    "L2-id": "O3-Vit",
    "L1-id": "FCln",
    "L0-id": "F",
    "Qty": 1
  },
  {
    "S0-id": "S",
    "Qty": 1,
    "Items": [
      {
        "L2-id": "Be-St",
        "L1-id": "BeSt",
        "L0-id": "H",
        "free": false
      },
      {
        "free": false,
        "L1-id": "HCS",
        "L0-id": "H",
        "L2-id": "MHC"
      },
      {
        "L1-id": "HMsg",
        "free": true,
        "L0-id": "H",
        "L2-id": "N-Oil-10"
      },
      {
        "free": false,
        "L2-id": "O3-Vit",
        "L1-id": "FCln",
        "L0-id": "F"
      }
    ],
    "S1-id": "Off-Re"
  },
  {
    "Items": [
      {
        "L2-id": "Be-St",
        "free": true,
        "L1-id": "BeSt",
        "L0-id": "H"
      },
      {
        "free": true,
        "L2-id": "MHC",
        "L1-id": "HCS",
        "L0-id": "H"
      },
      {
        "L2-id": "L-MHSp-S",
        "L0-id": "H",
        "free": false,
        "L1-id": "HSp"
      },
      {
        "L0-id": "F",
        "L2-id": "Ozo-Glw4",
        "L1-id": "Fac",
        "free": false
      }
    ],
    "S1-id": "Des",
    "Qty": 1,
    "S0-id": "S"
  }
]
 */
  const setCustomerCart = () => {
    console.log("customercart = ", customercart);
    for (const productset of customercart) {
      if (cartId !== false && productset.Phone === cartId) {
        __CartContext.setOptimizedCart(productset.Product_list);

        let cartList = [];
        for (const product of productset.Product_list) {
            if (product["S0-id"]==="S") {
                cartList.push(findSpeciality(product))
            } else {
                switch (product["L0-id"]) {
                    case "H":
                    const catalogh = Catalog.Hair_Care;
                    cartList.push(findCart(catalogh, product));
                    break;
                    case "F":
                    const catalogf = Catalog.Face_Care;
                    cartList.push(findCart(catalogf, product));
                    break;
                    case "B":
                    const catalogb = Catalog.Body_Care;
                    cartList.push(findCart(catalogb, product));
                    break;
                    default:
                    break;
                }                
            }
        }
        __CartContext.setCart(cartList);
      }
    }
  };

  const EndSession = async () => {
    let obj = {
      loginToken: loginToken,
      Phone: parseInt(cartId),
      cart: optimizedCart,
    };
    const res = await AxiosCall("/end-session", obj, setEndSessionRes);
    if (res.success) {
      setEndResUrl({
        url: "/active-sessions",
        status: true,
      });
    }
  };

  const AddProducts = () => {
    let obj = {
      loginToken: loginToken,
      Phone: cartId,
      products: __CartContext.optimizedCart,
    };

    axios
      .post(url + "/Salon-added-products", obj)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log("fetch error in active-sesssons", e);
      });
  };

  const RemoveSession = (Phn) => {
    let obj = {
      loginToken: loginToken,
      Phone: Phn,
    };
    axios
      .post(url + "/remove-session", obj)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SessionLogicContext.Provider
      value={{
        sessionObj,
        endResUrl,
        cartId,
        setCartId,
        openPrompt,
        setEndResUrl,
        GetSession,
        setCustomerCart,
        EndSession,
        AddProducts,
        setOpenPrompt,
        TogglePrompt,
        RemoveSession,
      }}
    >
      {children}
    </SessionLogicContext.Provider>
  );
}

// Step 3: Create a Custom Hook

export function useSessionLogic() {
  return useContext(SessionLogicContext);
}
export function useOpenPrompt() {
  return useContext(SessionLogicContext).openPrompt;
}
export function useGetSession() {
  return useContext(SessionLogicContext).GetSession;
}
export function useSetCustomerCart() {
  return useContext(SessionLogicContext).setCustomerCart;
}
export function useEndSession() {
  return useContext(SessionLogicContext).EndSession;
}
export function useAddProducts() {
  return useContext(SessionLogicContext).AddProducts;
}
export function useSetOpenPrompt() {
  return useContext(SessionLogicContext).setOpenPrompt;
}
export function useTogglePrompt() {
  return useContext(SessionLogicContext).TogglePrompt;
}
export function useRemoveSession() {
  return useContext(SessionLogicContext).RemoveSession;
}
