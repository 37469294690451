import React, { createContext, useContext, useState } from 'react';
import { loadHook, UpdateHook } from './functions/localStorage-and-Hooks';

// Step 1: Create a Context
const CartContext = createContext();

// Step 2: Provide the Context
export function CartStatesProvider({ children }) {

  const [cart, setCart] = useState(loadHook({ datatype: "Array", storageKey: "cart" }));
  UpdateHook({ storageKey: "cart", stateVar: cart })

  const [priceTable, setPriceTable] = useState(loadHook({ datatype: "Array", storageKey: "priceTable" }));
  UpdateHook({ storageKey: "priceTable", stateVar: priceTable })

  const [optimizedCart, setOptimizedCart] = useState(loadHook({ datatype: "Array", storageKey: "optimizedCart" }));
  UpdateHook({ storageKey: "optimizedCart", stateVar: optimizedCart })

  // ********* SEssionMsg *********

  const [sessionmsg, setSessionmsg] = useState(loadHook({ datatype: "Object", storageKey: "sessionmsg" }));
  UpdateHook({ storageKey: "sessionmsg", stateVar: sessionmsg })

  return (

    <CartContext.Provider value={{
      cart,
      setCart,

      priceTable,
      setPriceTable,

      optimizedCart,
      setOptimizedCart,

      sessionmsg,
      setSessionmsg

    }}>

      {children}
    </CartContext.Provider>
  );

}

// Step 3: Create a Custom Hook

export function useCartContext() {
  return useContext(CartContext);
}

export function useCart() {
  return useContext(CartContext).cart;
}
export function useSetCart() {
  return useContext(CartContext).setCart;
}

export function usePriceTable() {
  return useContext(CartContext).priceTable;
}

export function useOptimizedCart() {
  return useContext(CartContext).optimizedCart;
}


export function useSessionMsg() {
  return useContext(CartContext).sessionmsg;
}

export function useSetSessionMsg() {
  return useContext(CartContext).setSessionmsg;
}

export function useSetPriceTable() {
  return useContext(CartContext).setPriceTable;
}
