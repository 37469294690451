const zero4 =(e)=>{
    if(e<10 && e>=1){
      return `000${e}`;
    }
    else if(e<100 && e>=10){
      return `00${e}`;
    } 
    else if(e<1000 && e>=100){
      return `0${e}`;
    }
    else{
      return `${e}`;
    }
  }

export default zero4;

export function validateUsername(username) {
  // Check if the username is not empty
  if (!username) {
      return false;
  }

  // Check if the username length is between 3 and 20 characters
  if (username.length < 3 || username.length > 20) {
      return false;
  }

  // Check if the username contains only alphanumeric characters and underscores
  if (! /^(?=.*[a-zA-Z_])[a-zA-Z_.]+(?: [a-zA-Z_.]+){0,3}$/.test(username)) {
      return false;
  }

  // Username passed all checks, so it's valid
  return true;
}
export const zero2 =(e)=>{
  if(e<10 && e>=1){
    return `0${e}`;
  }
  else if(e<100 && e>=10){
    return `${e}`;
  }
}
// // Example usage:
// console.log(validateUsername("user123")); // true
// console.log(validateUsername("user_name")); // true
// console.log(validateUsername("us")); // false (too short)
// console.log(validateUsername("this_is_a_very_long_username")); // false (too long)
// console.log(validateUsername("user$name")); // false (contains invalid character)
// console.log(validateUsername("")); // false (empty)


export const addComma = (e)=>{
  try {

      // Convert number to string
      let numStr = e.toString();
      
      // Initialize variables
      let formattedStr = '';
  
      // Start from the end of the string
      for (let i = numStr.length - 1, count = 0; i >= 0; i--, count++) {
          // Add comma after 3, 2, 2, 2 digits
          if (count === 3 || count === 5 || count === 7) {
              formattedStr = ',' + formattedStr;
          }
          // Concatenate current character to the formatted string
          formattedStr = numStr[i] + formattedStr;
      }
  
      return formattedStr;
  
  } catch (f) {
    return e;    
  }
}