import{ useEffect, useState } from 'react';
import "../../Style/CustomerStyle/impact.css"; 
import { useImpactRouter } from '../../Contexts/APP/routing-logic';

function Impact() {
    console.log("Impact Page is called.");
    const ImpactRouter = useImpactRouter();
    const ID = (e)=>{return document.getElementById(e);}
    const [timer , setTimer ] = useState(3);
    useEffect(()=>{
        if (timer>=0) {
          if (timer%2===0) {
            ID("Impact-content").style.scale=1;
          } else {
            ID("Impact-content").style.scale=1.1;
          }
          setTimeout(()=>{setTimer(timer-1);},800);
        }else{
            setTimeout(()=>{ImpactRouter();},3500);
        }
    },[timer])

    return (
    <>
      {/* <div id="Impact-porda"></div> */}
        <div id='Impact-content' className='Impact-container'>
          <img className='Impact-img' src="./Images/impact.svg" alt="" />
          <div className='Impact-quote'>
            <p> "Grooming is the Secret to real elegance." <br />
                Help us personalize your experience. </p> 
          </div>
      </div>
    </>
  )
}

export default Impact;
