import "../../Style/CatalogStyle/popup.css";
import Popupsingleitem from "./cart/popupsingleitem";
const PopUpScreen = ({Popupdata}) => {
  console.log("Popup Screen is called.");
  console.log("Popupdata : ",Popupdata)
let items ; 
    if (Popupdata.CategoryPrice===undefined) {
      items = Popupdata;
    } else {
      items = Popupdata.Items
    }
  return (
    <>
      {/* <span style={{wordWrap:"break-word"}}>    {JSON.stringify(items)}    </span>  */}
      {items.map((item) => (
          <Popupsingleitem key={item.Product_id} item={item} />
      ))}
    </>
  );
};
export default PopUpScreen;