import React, { useEffect } from "react";
import "../../Style/OwnerStyle/ownerland.css";
import { Link, useNavigate } from "react-router-dom";
import { useActiveSessions } from "../../Contexts/APP/routing-logic";

const Ownerlandpage = () => {
  // const __SessionContext = useContext(SessionContext);
  // const navigate = useNavigate();
  // if(getCookie("loginToken") === null){
  //   navigate("/");
  // }
  // else{
  // useEffect(()=>{
  //   if (__SessionContext.Sessions) {
  //     navigate("/active-sessions");
  //   }
  // },[__SessionContext.Sessions])


    const ActiveSessions = useActiveSessions();
    // const getSession = ()=>{__SessionContext.getSession();}
    
    return (
      <>
      <div className="land-container">
        <div className="ownername-img-session">
          <img id="OwnerImage" src="./Images/Owner-Images/Salon-0001.jpeg" alt="Owner Image" />
          <h2 style={{color:"#fff",marginTop:"10px",fontSize:"1.1rem"}}>Rahat Ali - Grooming Consultant</h2>
            <button onClick={ActiveSessions} style={{backgroundColor:"#fff",boxShadow:"0px 0px 8px #f0f0f0"}}>
              <strong style={{color:"black"}}>Active Sessions</strong>
            </button>
        </div>
        <center>
          <div className="features">
            <div className="analytics-section" style={{opacity:1}}>
              <span className="coming-soon" style={{visibility:"hidden"}}>COMING SOON</span>
              <h3>Sales Analytics</h3>
              <Link to="/store">
                <img style={{border:"2px solid #fff"}} src="./Images/sales-analytics.jpeg" alt="" />
              </Link>
            </div>
            <div className="analytics-section" >
            <span className="coming-soon">COMING SOON</span>

            
              <h3>Catalog Service  </h3>
              {/* <Link to="#"> */}
                <img style={{ filter: 'blur(4px)',border:"2px solid white" }} src="./Images/catalog.png" alt="" />
              {/* </Link> */}
            </div>
            <div className="analytics-section">
            <span className="coming-soon">COMING SOON</span>

              <h3>Marketing</h3>
              {/* <h4>Coming Soon</h4> */}
              {/* <Link to="#"> */}
                <img src="./Images/marketing.jpeg" alt="" 
                 style={{ filter: 'blur(4px)',border:"2px solid white" }}
                />
              {/* </Link> */}
            </div>
            <div className="analytics-section">
            <span className="coming-soon">COMING SOON</span>

              <h3>Bookings</h3>
              {/* <Link to="#"> */}
                <img style={{ filter: 'blur(4px)',border:"2px solid white" }}  src="./Images/bookings.jpeg" alt=""  />
              {/* </Link> */}
            </div>
          </div>
        </center>
      </div>
    </>
  );
};

// }
export default Ownerlandpage;
