import { useEffect } from "react";
import { getCookie } from "./Cookies-and-Hooks";

const Parse = (e) => {
    // console.log("Parse",e);
    if (e === null || e === "undefined" || e === undefined) {
        return false;
    }
    else {
        return JSON.parse(e);
    }
}


const getParams = (type, data) => {
    const Type = type.toLowerCase()
    switch (Type) {
        case "array":
            if (data === null || data === undefined || data === "undefined" || data === false || data === "" ) {
                return [];
            }
            else {
                return data;
            }
            break;
        case "object":
            if (data === null || data === undefined || data === "undefined" || data === false || data === "" ) {
                return {};
            }
            else {
                return data;
            }
            break;
        case "string":
            if (data === null || data === undefined || data === "undefined" || data === false ) {
                return "";
            }
            else {
                return data;
            }
            break;
        default:
            break;
    }
}

/**
 * 
 * @param {{datatype:"array|object",storageKey:"cart"}} StateObj  
 * @returns
 */

export function loadHook(StateObj) {

    /**
     * ******* High Level Data Structure *******
     * StateObj = {
     *      datatype : "Array",
     *      storageKey :"",
     *   }
     * 
     */

    // Reading From Storage.
    let data = Parse(localStorage.getItem(StateObj.storageKey));

    // console.log("loadHook Value : data ",data);
    // Setting the Appropriate Value.
    const Data =  getParams(StateObj.datatype, data);
    // console.log("loadHook Value : Data ",Data);

    // Setting in the Storage.
    if (data === false) {
        localStorage.setItem(StateObj.storageKey, JSON.stringify(Data));
    }

    // Returning the Appropriate Value. 
    return Data ;  

}

/**
 * 
 * @param {{storageKey:"cart", stateVar:"cart"}} StateObj  
 */

export function UpdateHook(StateObj) {

    /**
     * ******* High Level Data Structure *******
     * StateObj = {
     *      dataType : "Array",
     *      stateVar :cart,
     *   }
     * 
     */

    useEffect(() => {
        localStorage.setItem(StateObj.storageKey, JSON.stringify(StateObj.stateVar));
        // console.log("At update Hook : ",StateObj.storageKey, StateObj.stateVar);
    }, [StateObj.stateVar]);

}

// export {Parse, getParams}



export function ResetApp(){
    const LoginObj = getCookie("LoginObj");
    if (LoginObj===null) {
        localStorage.clear();        
    }
}