import "../../../Style/CatalogStyle/popup.css";
import { useProductState } from "../../../Contexts/APP/cart-logic";
import { Extended_Price } from "../../../Functions/price-functions";

const Popupsingleitem = ({ item }) => {
  console.log("Popup single item is solved.");

  const ProductState = useProductState();

  const colorSet = [
    "#ff4545",
    "#ffa534",
    "rgb(255, 219, 0)",
    "rgb(142, 177, 8)",
    "rgb(41, 175, 0)",
  ];

  const getColor = (i) => {
    let j = parseInt(i);
    return colorSet[j - 1];
  };

  const btnurl = `/Images/${item.taken ? "RemoveFromCart" : "AddToCart"}.svg`;

  return (
    <>
      <div>
        <div className="popup-grid-item">
          <div className="popup-img-left">
            <img src={"/Images/Storage/" + item.ItemsUrl} alt="Items" />
            <h4 style={{color:"black", marginTop:"0px",fontSize:"18px"}}>
              {" "}
              {item.Ratings} &nbsp;
              <span
                style={{ color: getColor(item.Ratings) }}
                className="rating-star">
                ★
              </span>
              &nbsp; {item.Sales_unit} K {" "}
            </h4>
          </div>
          <div className="popup-img-right">
            <div className="popupText">
              <div className="headingAndbrandLogo">
              <div className="heading">{item.Name} 
              </div>

              {/* <div className="brandLogo"><img src="/Images/garnierlogo.png" alt="" /></div> */}
              </div>
            


              <div
                className="desc"
                style={{
                  textAign: "justify",
                  textJustify: "inter-word",
                  wordWrap: "break-word",
                }}>
                {  item.About_Text.slice(0, 40)}{" "}
              </div>
            </div>
            <div className="price">
              {" "}
              Rs. {item.Price} &nbsp;
              <span className="real-price">
                (Rs.{Extended_Price(item.Price, item.Discount)})
              </span>
              <br />
              <span className="price-off">({item.Discount}%OFF)</span>
            </div>
          </div>
        </div>
        {item["S0-id"]!=="S" && <button
          id={item.Product_id}
          className="plus-icon"
          onClick={() => {
             ProductState(item);
          }}>
          <img srcSet={btnurl} alt="" />{" "}
        </button>}
      </div>
    </>
  );
};

export default Popupsingleitem;