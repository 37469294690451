import { NavLink, Outlet } from 'react-router-dom';
import "../../Style/CatalogStyle/navigation.css";

const Navigation = () => {
    console.log("Catalog : inside Catalog Navigation Called.");
      return (
        <>
        <div>
          <nav id="catalog-Nav" className="catalog-nav">
            <NavLink 
            className="catalog-nav-btn" id='hair_care'   to="/home/catalog/hair_care">Hair Care</NavLink>
            <NavLink 
             className="catalog-nav-btn" id='face_care'   to="/home/catalog/face_care">Face Care</NavLink>
            <NavLink 
            className="catalog-nav-btn" id='body_care'   to="/home/catalog/body_care">Body Care</NavLink>
          </nav>
        </div>
    <div>
      <Outlet/>
    </div>
    </>
  );
};

export default Navigation
