import React ,{useContext, useEffect, useState} from 'react'
import "../../Style/CustomerStyle/startsession.css"; 
import { useSendCartData } from '../../Contexts/APP/cart-logic';

const Startsession = () => {


  const SendCartData = useSendCartData();
  const [timer, setTimer] = useState(30);

  useEffect(()=>{
    SendCartData();
    console.log("session list called");

  },[])

  useEffect(()=>{
    setTimeout(()=>{
      if(timer!==0){setTimer(timer-1);}
    },1000);

  },[timer]);
  
  const ID = (e) => {
    return document.getElementById(e);
  };
 
  const zero = (e) => {
    if (e <= 9) {
      return `0${e}`;
    } else {
      return e;
    }
  };

  return (
 <>
 <div className="startsession-container">
    <img src="./Images/barbar.png" alt="Barbar Image" /> <br />
    <p id="greeting1">Thanks Sir for Choosing the services !! </p>
    <p id="greeting2">Groooming Consultant is <br /> getting ready to serve you. !! </p>
    <div className="timer">
    <img src="./Images/timer.png" className='time-icon' alt="Timer Image" /> 
    <span className="wait-time">{zero(Math.floor(timer/60))}.{zero(Math.floor(timer%60))}</span>
    </div>   
 </div>
 </>
  )
}

export default Startsession
